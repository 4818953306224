@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?9n8xzw');
  src:  url('fonts/icomoon.eot?9n8xzw#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?9n8xzw') format('truetype'),
    url('fonts/icomoon.woff?9n8xzw') format('woff'),
    url('fonts/icomoon.svg?9n8xzw#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bed:before {
  content: "\e90a";
}
.icon-hotel:before {
  content: "\e90a";
}
.icon-checkmark:before {
  content: "\e90b";
}
.icon-tick:before {
  content: "\e90b";
}
.icon-correct:before {
  content: "\e90b";
}
.icon-accept:before {
  content: "\e90b";
}
.icon-ok:before {
  content: "\e90b";
}
.icon-map:before {
  content: "\e908";
}
.icon-location:before {
  content: "\e909";
}
.icon-instagram:before {
  content: "\e907";
}
.icon-bed1:before {
  content: "\e900";
}
.icon-telephone60:before {
  content: "\e902";
}
.icon-arrow483:before {
  content: "\e000";
}
.icon-arrow487:before {
  content: "\e001";
}
.icon-arrowhead7:before {
  content: "\e002";
}
.icon-bank24:before {
  content: "\e003";
}
.icon-building113:before {
  content: "\e004";
}
.icon-bulbs1:before {
  content: "\e005";
}
.icon-cars5:before {
  content: "\e006";
}
.icon-facebook55:before {
  content: "\e009";
}
.icon-four60:before {
  content: "\e00a";
}
.icon-google116:before {
  content: "\e00b";
}
.icon-hand260:before {
  content: "\e00c";
}
.icon-info31:before {
  content: "\e00d";
}
.icon-left-arrows:before {
  content: "\e00e";
}
.icon-linkedin11:before {
  content: "\e00f";
}
.icon-magnifying-glass34:before {
  content: "\e010";
}
.icon-menu55:before {
  content: "\e011";
}
.icon-navigate6:before {
  content: "\e012";
}
.icon-pin71:before {
  content: "\e013";
}
.icon-right-arrow49:before {
  content: "\e014";
}
.icon-trees16:before {
  content: "\e015";
}
.icon-twitter1:before {
  content: "\e016";
}
.icon-youtube18:before {
  content: "\e017";
}
.icon-house:before {
  content: "\e901";
}
.icon-aankoopmakelaar:before {
  content: "\e903";
}
.icon-bedrijfsmatig:before {
  content: "\e904";
}
.icon-huis-verkopen:before {
  content: "\e905";
}
.icon-huur-en-verhuur:before {
  content: "\e906";
}
