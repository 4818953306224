#object {
    .main_photo_wrapper {
        position: relative;
        padding-bottom: 40%;
        min-height: 300px;
        background-size: cover;
        background-position: center center;

        .overlay {
            background-color: rgba(221, 4, 43, 0.8);
            color: #fff;
            padding: 10px;
            position: absolute;
            top: 0;
            text-align: center;
            left: 50%;
            width: 100%;
            transform: translate(-50%);
            font-size: 20px;
            text-transform: uppercase;
            font-family: $font-family-serif;
        }

        .container {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: (translate(-50%, -50%));
            width: 100%;

            h1 {
                width: 100%;
                float: left;

                //font-size: 28px;
                color: #fff;
                text-shadow: -1px 1px 10px rgba(0, 3, 3, 0.75);
                margin: 0 0 20px;
                padding: 0;
            }

            > span {
                color: #fff;
                background-color: $red;
                font-family: $font-family-serif;

                font-size: 16px;
                padding: 5px 20px;

                &:hover {
                    color: #f66;
                    background-color: #fff;
                    border-color: #fff;
                }
            }

        }
    }
    #bids {
        position: relative;
        background: #2F383D;
        padding: 20px;
        h3{
            color: #fff;
            margin-bottom: 20px;
            a{
                text-decoration: none;
                float: right;
                color: #2F383D;
                background: #fff;
                padding: 5px;
            }
        }
        .bid {
            position: relative;
            margin: 0 0 15px -15px;
            padding: 0 15px 15px;
            color: #FFF;
            font-size: 16px;

            &:after {
                content: ' ';
                position: absolute;
                bottom: -1px;
                left: 15px;
                height: 1px;
                width: calc(100% - 15px);
                background: #666;
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }

            &.added {
                animation: newBid 1s;
            }

            .price {
                float: right;
            }

            .time {
                font-size: 14px;
                color: #999;
            }

        }
    }
    @keyframes newBid {
        0% {
            // Do nothing
        }
        50% {
            box-shadow: 0 0 30px $red;
            transform: scale(1.1);
        }
        100% {
            transform: scale(1);
        }
    }
    #bids{

        h2{
            margin-top: 0;
        }


    }
    #bid{
        background: #2F383D;
        padding: 20px 0;
        color: #fff;
        span{
            display: block;
            color: #fff;
            &.highest-bid{
                display: inline;
                .price{
                    background: #fff;
                    display: inline;
                    color: #2F383D;
                    padding: 5px;
                    font-weight: bold;
                }
            }
            &.timer{
                color: $red;
                display: inline;
            }
            &.prijs{
                font-size: 30px;
                font-weight: bold;
            }
        }
        .btn-red{
            display: inline-block;
            border: none;
            margin-top: 20px;
        }
    }
    h2 {
        color: $red;
    }

    .details {
        display: flex;
        flex-wrap: wrap;
        .col-sm-6{
            float: none;
            order: 2;
            &.bids-wrapper{
                margin-bottom: 25px;
                order: 1;
            }
        }

        h4 {
            color: $red;
            margin-botton: 5px;
        }

        dl {
            width: 100%;
            margin: 0 0 40px 0;
            border-top: 1px solid $light-grey;

            dd, dt {
                display: inline-block;
                border-bottom: 1px solid $light-grey;
                width: 49%;
                line-height: 40px;
                margin: 0px;
            }
        }
    }

    .text_detalis {
        float: left;
        width: 100%;
        margin: 0;
        padding: 50px 0;

        h3 {

            font-weight: normal;
            color: #292929;
            line-height: 30px;
            margin: 0 0 25px;
        }
    }

    .map_sec {
        h1 {

            font-weight: normal;
            font-size: 44px;
            color: $red;
            line-height: 70px;
            margin: 0 0 25px;
        }

        .maps {
            width: 100%;
            height: 400px;

            iframe {
                pointer-events: none;
            }
        }
    }

    .video_sec, .view360_sec {
        position: relative;

        h1 {

            font-weight: normal;
            font-size: 44px;
            color: $red;
            line-height: 70px;
            margin: 0 0 25px;
        }

        p {
            padding: 5px 0;

            a {
                display: block;
            }
        }

        .video-container {
            position: relative;
            padding-bottom: 56.25%;
            padding-top: 0px;
            height: 0;
            overflow: hidden;
        }

        .video-container iframe,
        .video-container object,
        .video-container embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .view-eighth .mask {
        background-color: rgba(229, 66, 95, 0.5);

        padding: auto;
        -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
        opacity: 0;
        -webkit-transition: all 0.3s ease-out 0.5s;
        -moz-transition: all 0.3s ease-out 0.5s;
        -o-transition: all 0.3s ease-out 0.5s;
        -ms-transition: all 0.3s ease-out 0.5s;
        transition: all 0.3s ease-out 0.5s;
        cursor: pointer;
    }

    .view-eighth h2 {
        -webkit-transform: translateY(-200px);
        -moz-transform: translateY(-200px);
        -o-transform: translateY(-200px);
        -ms-transform: translateY(-200px);
        transform: translateY(-200px);
        -webkit-transition: all 0.2s ease-in-out 0.1s;
        -moz-transition: all 0.2s ease-in-out 0.1s;
        -o-transition: all 0.2s ease-in-out 0.1s;
        -ms-transition: all 0.2s ease-in-out 0.1s;
        transition: all 0.2s ease-in-out 0.1s;
    }

    .view-eighth p {
        color: #333;
        -webkit-transform: translateY(-200px);
        -moz-transform: translateY(-200px);
        -o-transform: translateY(-200px);
        -ms-transform: translateY(-200px);
        transform: translateY(-200px);
        -webkit-transition: all 0.2s ease-in-out 0.2s;
        -moz-transition: all 0.2s ease-in-out 0.2s;
        -o-transition: all 0.2s ease-in-out 0.2s;
        -ms-transition: all 0.2s ease-in-out 0.2s;
        transition: all 0.2s ease-in-out 0.2s;
    }

    .view-eighth a.info {
        -webkit-transform: translateY(-200px);
        -moz-transform: translateY(-200px);
        -o-transform: translateY(-200px);
        -ms-transform: translateY(-200px);
        transform: translateY(-200px);
        -webkit-transition: all 0.2s ease-in-out 0.3s;
        -moz-transition: all 0.2s ease-in-out 0.3s;
        -o-transition: all 0.2s ease-in-out 0.3s;
        -ms-transition: all 0.2s ease-in-out 0.3s;
        transition: all 0.2s ease-in-out 0.3s;
    }

    .view-eighth:hover .mask {
        -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
        filter: alpha(opacity=100);
        opacity: 1;
        top: 0px;
        -webkit-transition-delay: 0s;
        -moz-transition-delay: 0s;
        -o-transition-delay: 0s;
        -ms-transition-delay: 0s;
        transition-delay: 0s;
        -webkit-animation: bounceY 0.9s linear;
        -moz-animation: bounceY 0.9s linear;
        -ms-animation: bounceY 0.9s linear;
        animation: bounceY 0.9s linear;
        cursor: pointer;
    }

    .view-eighth:hover h2 {
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -o-transform: translateY(0px);
        -ms-transform: translateY(0px);
        transform: translateY(0px);
        -webkit-transition-delay: 0.4s;
        -moz-transition-delay: 0.4s;
        -o-transition-delay: 0.4s;
        -ms-transition-delay: 0.4s;
        transition-delay: 0.4s;
    }

    .view-eighth:hover p {
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -o-transform: translateY(0px);
        -ms-transform: translateY(0px);
        transform: translateY(0px);
        -webkit-transition-delay: 0.2s;
        -moz-transition-delay: 0.2s;
        -o-transition-delay: 0.2s;
        -ms-transition-delay: 0.2s;
        transition-delay: 0.2s;
    }

    .view-eighth:hover a.info {
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -o-transform: translateY(0px);
        -ms-transform: translateY(0px);
        transform: translateY(0px);
        -webkit-transition-delay: 0s;
        -moz-transition-delay: 0s;
        -o-transition-delay: 0s;
        -ms-transition-delay: 0s;
        transition-delay: 0s;
    }

    @keyframes bounceY {
        0% {
            transform: translateY(-205px);
        }
        40% {
            transform: translateY(-100px);
        }
        65% {
            transform: translateY(-52px);
        }
        82% {
            transform: translateY(-25px);
        }
        92% {
            transform: translateY(-12px);
        }
        55%, 75%, 87%, 97%, 100% {
            transform: translateY(0px);
        }
    }
    @-moz-keyframes bounceY {
        0% {
            -moz-transform: translateY(-205px);
        }
        40% {
            -moz-transform: translateY(-100px);
        }
        65% {
            -moz-transform: translateY(-52px);
        }
        82% {
            -moz-transform: translateY(-25px);
        }
        92% {
            -moz-transform: translateY(-12px);
        }
        55%, 75%, 87%, 97%, 100% {
            -moz-transform: translateY(0px);
        }
    }
    @-webkit-keyframes bounceY {
        0% {
            -webkit-transform: translateY(-205px);
        }
        40% {
            -webkit-transform: translateY(-100px);
        }
        65% {
            -webkit-transform: translateY(-52px);
        }
        82% {
            -webkit-transform: translateY(-25px);
        }
        92% {
            -webkit-transform: translateY(-12px);
        }
        55%, 75%, 87%, 97%, 100% {
            -webkit-transform: translateY(0px);
        }
    }

    .view {
        width: 100%;
        height: auto;
        float: left;
        overflow: hidden;
        position: relative;
        text-align: center;
        cursor: pointer;
    }

    .view .mask, .view .content {
        width: 100%;
        height: 100%;
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;
    }

    .view img {
        display: block;
        position: relative;
    }

    .view h2 {
        text-transform: uppercase;
        color: #fff;
        text-align: center;
        position: relative;
        font-size: 26px;
        padding: 10px;
        margin: 80px 0 0 0;
        color: #fff;
        cursor: pointer
    }

    .view p {
        font-family: Georgia, serif;
        font-style: italic;
        font-size: 12px;
        position: relative;
        color: #fff;
        padding: 10px 20px 20px;
        text-align: center;
    }

    .view a.info {
        display: inline-block;
        text-decoration: none;
        padding: 7px 14px;
        background: #000;
        color: #fff;
        text-transform: uppercase;
        -webkit-box-shadow: 0 0 1px #000;
        -moz-box-shadow: 0 0 1px #000;
        box-shadow: 0 0 1px #000;
    }

    .view a.info:hover {
        -webkit-box-shadow: 0 0 5px #000;
        -moz-box-shadow: 0 0 5px #000;
        box-shadow: 0 0 5px #000;
    }
}

.row-purchased {
    display: flex;
    flex-wrap: wrap;
}

.row-purchased > [class*='col-'] {
    display: flex;
    flex-direction: column;
}

.project-purchased p {
    margin-left: 20px;
    margin-right: 20px;
}

#objects {
    .object_title_sec {
        ul {
            &.icon_link {
                float: right;
                margin: 0;
                padding: 0;

                li {
                    display: inline-block;
                    margin: 0;
                    padding: 0;

                    a {
                        i {
                            width: 52px;
                            font-size: 24px;
                            color: $red;
                            text-align: center;
                            display: block;
                            margin: 0;
                            padding: 36px 0 0 0;
                        }
                    }
                }
            }
        }

        .dropdown_small {
            float: right;
            margin: 23px 20px 0 0;

            .btn-primary {
                color: $red;
                background-color: transparent !important;
                width: 100%;
                border-radius: 0;
                -webkit-border-radius: 0;

                font-weight: normal;
                font-size: 20px;
                border-color: transparent;

                &:hover {
                    color: darken($red, 0.8);
                    border-color: transparent;
                }
            }

            .dropdown-menu {
                border-radius: 0;

                font-size: 18px;
                background-color: #fff;
                border: $light-grey 1px solid;
                margin: 2px 0 0;
                padding: 0;
            }

            .dropdown-menu > li {
                &.active {
                    > a {
                        background-color: $light-grey;
                    }
                }

                > a {
                    color: #000;
                    font-size: 16px;
                }
            }
        }
    }
}

.expected-description {
    margin: 15px 20px 5px 20px;
    font-weight: normal;
    color: #32383d;
    line-height: 24px;
}

.expected-row {
    display: flex;
    flex-wrap: wrap;

    > [class*='col-'] {
        display: flex;
        flex-direction: column;

        .project_detalis {
            height: 100%;
        }
    }
}

.expected-prijs {
    margin: 16px 20px 5px 20px !important;
}

#bidModal,
#successModal {
    font-size: 16px;
    color: #000;

    .close {
        float: right;
        font-size: 20px;
    }

    h2 {
        color: #202020;
        margin-bottom: 0;
        font-size: 32px;
        font-weight: bold;

        span {
            display: block;
            font-weight: normal;
            font-size: 24px;
        }
    }

    h2,
    h3 {
        color: #202020 !important;
    }

    .modal-body {
        background: #F5F5F5;
        padding-bottom: 0;

        form {
            margin-bottom: 0;

            .form-group {
                input {
                    &.error {
                        border-color: #C00 !important;
                    }
                }
            }
        }

        h3 {
            color: #202020;
            font-size: 20px;

            + small {
                display: inline-block;
                margin: -10px 0 15px 0;
            }
        }

        .title {
            color: #949494;
        }
        .price {
            display: block;
            font-size: 20px;
            font-weight: bold;
            white-space: unset;
        }

        .form-control {
            background: #fff;
        }

        table {
            border-bottom: 1px solid #e5e5e5;
            width: 100%;
            margin-bottom: 30px;

            tr {
                td {
                    padding: 8px;
                    border-top: 1px solid #e5e5e5;

                    &:last-child {
                        text-align: right;
                    }
                }

                &:nth-child(odd) {
                    background: #FFF;
                }

                &:last-child {
                    td {
                        font-weight: bold;
                    }
                }
            }
        }

        label {
            display: block;
            position: relative;
            padding-left: 35px;
            margin-bottom: 12px;
            cursor: pointer;
            user-select: none;
            transition: all 0.3s;
            line-height: 25px;

            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;

                &:checked ~ .checkmark {
                    background-color: $red;
                    border: 1px solid $red;

                    &:after {
                        display: block;
                    }
                }
            }

            &:hover {
                input:not(:checked) ~ .checkmark {
                    background-color: #F5F5F5;
                }
            }

            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 25px;
                width: 25px;
                background-color: #FFF;
                border: 2px solid $red;

                &:after {
                    content: "";
                    position: absolute;
                    display: none;
                    left: 9px;
                    top: 4px;
                    width: 6px;
                    height: 12px;
                    border: solid white;
                    border-width: 0 4px 4px 0;
                    transform: rotate(45deg);
                }

                &.error {
                    border-color: #C00;
                }
            }
        }

        .button_wrapper {
            border-top: 1px solid #e5e5e5;
            background: #FFF;
            margin: 30px -15px 0 -15px;
            padding: 15px;
            font-size: 14px;

            &.no-margin-top {
                margin-top: 0 !important;
            }

            .form-group {
                .control-label {
                    margin-bottom: 5px;
                    padding-left: 0;
                }
            }

            label.error {
                color: #C00;

                a {
                    color: #F00;
                }
            }

            button {
                margin-bottom: 0;
                margin-top: 20px;
                display: block;
                border: none;
                width: 100%;
            }
        }
    }
}

@media (max-width: 1024px) {
    section .tab_details .nav-tabs > li > a {
        font-size: 16px !important;
        padding-left: 0;
        padding-right: 8px;
    }
}

@media (max-width: 768px) {
    #objects {

        .object_title_sec .dropdown_small {
            float: left;
            margin-top: 10px;

            .btn {
                padding-left: 0;
            }
        }
    }

    section .tab_details .nav-tabs > li > a {
        font-size: 16px !important;
        padding-left: 0;
    }
}

@media (min-width: 768px) {
    #object {
        .details{
            .col-sm-6{
                order: 1;
                &.bids-wrapper{
                    margin-bottom: 0px;
                    order: 2;
                }
            }
        }
        .main_photo_wrapper {
            .overlay {
                font-size: 30px;
            }

            .container {
                h1 {
                    font-size: 35px;
                }

                > span {
                    font-size: 25px;
                }
            }
        }
    }
}

@media (min-width: 992px) {
    #object {
        .main_photo_wrapper {
            min-height: 600px;
            .overlay {
                font-size: 35px;
            }

            .container {
                h1 {
                    font-size: 50px;

                }

                > span {
                    font-size: 35px;
                }

                .bid{
                    background: #2F383D;
                    float: left;
                    padding: 25px 80px 25px 25px;
                    color: #fff;
                    > span{
                        display: block;
                        &.highest-bid{
                            display: inline;
                            .price{
                                background: #fff;
                                display: inline;
                                color: #2F383D;
                                padding: 5px;
                                font-weight: bold;
                            }
                        }
                        &.prijs{
                            font-size: 40px;
                            font-weight: bold;
                        }
                        &.timer{
                            color: $red;
                            display: inline;
                        }
                    }
                    .btn-red{
                        display: inline-block;
                        border: none;
                        margin-top: 20px;

                    }
                }
            }
        }
    }
}

@media (min-width: 1200px) {
    #object {
        .main_photo_wrapper {
            .overlay {
                font-size: 40px;
            }

            .container {
                h1 {
                    font-size: 55px;
                }

                > span {
                    font-size: 40px;
                }
            }
        }
    }
}