.contact_sec {
  float: left;
  width: 100%;
  margin: 0;
  padding: 0 0 50px;

  h1 {

    font-weight: normal;

    line-height: 150px;
    margin: 0;
  }

  h3 {
    font-size: 20px;
    font-weight: normal;
    margin: 0 0 5px;
    line-height: 32px;
  }

  p {

    font-size: 16px;
    line-height: 26px;
    margin-bottom: 10px;

    a {
      color: $red;
      font-size: 16px;
      text-decoration: none;
    }
  }

  .call {

    font-weight: normal;
    font-size: 22px;
    color: #31383d;
    margin-bottom: 25px;
  }

}