.container-header {
  width: 1300px;
}

#header {
  &.affix {
    position: fixed;
    top: 0;
    display: block;
    z-index: 1000;
    z-index: 1000;
    -webkit-transition: all .3s linear;
    -moz-transition: all .3s linear;
    -o-transition: all .3s linear;
    transition: all .3s linear;
    -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);

    .menu_logo {
      opacity: 1;
      display: block;
      -webkit-transition: all .2s ease-in;
      -moz-transition: all .2s ease-in;
      -o-transition: all .2s ease-in;
      transition: all .2s ease-in;

      img {
        width: 65px !important;
      }
    }
  }
}

header {
  .top_line {
    width: 100%;
    float: left;
    background-color: #fff;
    border-bottom: #e5ebed 1px solid;
    margin: 0;
    padding: 0;

    .call {
      font-weight: normal;
      font-size: 22px;
      line-height: 84px;
      text-align: right;
      float: right;

      a {
        color: #d8282f;
        text-decoration: none;

        &:hover {
          color: darken(#d8282f, 10%);
        }
      }
    }

    form {
      .form-control {
        .input-placeholder {
          color: #ccc !important;
        }
      }
    }

    .navbar-brand {
      padding-top: 17px;
      padding-left: 0;

      > img {
        display: block;
        max-width: 255px;
        height: auto;
      }
    }

    .navbar-default {

      .navbar-brand {
        height: auto;
        position: absolute;
        top: -71px;
        margin: 0;
        padding: 0;

        img {
          width: 100%;
          max-width: 241px;
        }
      }
    }

    form {
      float: right;
      margin-right: 20px;
      //border-right: 1px solid #e5ebed;
      //border-left: 1px solid #e5ebed;
      margin-top: 5px;
      margin-bottom: 0;
      padding: 16px 20px;

      .form-control {
        width: 100%;
        height: 40px;
        font-size: 16px;
        line-height: 1.42857143;

        color: #000 !important;
        background-color: #fff;
        border: 1px solid $light-grey;
        box-shadow: none;
        border-radius: 0;
        padding: 6px 12px;
      }

      .btn-danger {
        color: #fff;
        background-color: $light-grey;
        -webkit-border-radius: none;

        font-weight: normal;
        font-size: 16px;
        text-shadow: none;
        border-radius: 0;
        width: 40px;
        border-color: $light-grey;
        height: 40px;
      }

      ul {
        &.dropdown {
          z-index: 10;
          border: 1px solid #CCC;
        }
      }
    }

    .flag {
      float: right;
      //border-right: #e5ebed 1px solid;
      //border-left: #e5ebed 1px solid;
      margin-left: 15px;
      padding: 29px 15px;
    }

    .search_icon {
      float: right;
      border-right: #e5ebed 1px solid;
      margin-right: 15px;
      cursor: pointer;
      padding: 35px 15px;
    }

    #custom-search-input {
      line-height: 45px !important;
      width: 190px;
      float: right;
      display: none;
      margin: 0;
      padding: 0;

      .search-query {
        padding-right: 3px;
        width: 145px;
        height: 84px !important;
        border: none;

        font-size: 15px;

        .form-control {
          &:focus {
            box-shadow: none;
          }
        }
      }

      button {
        border: 0;
        background: none;
        margin-top: -2px;
        margin-bottom: 0;
        color: #31383d;
        font-size: 20px;
      }

      .input-group-btn {
        border-right: #e5ebed 1px solid;
        padding: 0;
      }
    }
  }

  .language-mobile {
    position: absolute;
    right: 90px;
    top: 20px;
  }

  .navbar-toggle-search {
    position: absolute;
    right: 125px;
    top: 20px;
    background: none;
    border-color: transparent;
    border-radius: 0;
  }

  #mobileSearch {
    .btn {
      background-color: #54565a;
      border-color: #54565a;
    }
  }

  .nav_sec {
    width: 100%;
    float: left;
    background-color: #32383d;
    margin: 0;
    padding: 0;

    .call_icon {
      i {
        float: right;
        width: 35px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        font-size: 18px;
        color: #fff;
        background-color: #d8282f;
        border-radius: 50%;
        margin: 18px 0;
        display: none;
      }
    }

    .navbar {
      .navbar-collapse {
        padding-left: 0;
        padding-right: 0;
      }

      .navbar-nav > li > .dropdown-menu {
        margin-top: -1px;
      }
    }

    .navbar-default {
      background: none;
      border: none;
      border-radius: 0;
      margin: 0;
      padding: 0;

      .navbar-nav > li {
        > a {
          //font-size: 16px;
          color: #fff;

          font-weight: normal;
          line-height: 50px;
          padding: 0 15px;
          text-transform: uppercase;
        }
      }

      .navbar-nav > li {
        #custom-search-input {
          line-height: 45px !important;
          width: 260px;
          margin: 0;
          padding: 0;
        }

        .hide {
          display: block !important;
        }
      }
    }

    .navbar-nav {
      float: left;
      margin: 0;
    }

    .navSearchEnquiries {
      position: relative;

      span {
        right: 10px;
        top: 5px;
        color: #fff;
        background: $red;
        font-size: 12px;
        border-radius: 50%;
        height: 20px;
        width: max-content;
        padding: 1px 4px 0px;
        position: absolute;
      }
    }

    .dropdown-menu {
      z-index: 99999;
    }

    .dropdown-menu > li > a {
      color: #31383d;
      padding: 3px 15px;

      &:hover, &:focus {
        background: #e5ebed;
        color: #31383d;
      }
    }
  }

  .menu_logo {
    float: left;
    opacity: 0;
    display: none;
    margin: 2px 10px 0 0;
    padding: 0;
  }
}

@media (max-width: 767px) {
  header {
    .nav_sec {

      nav {
        .navbar-header {
          form {
            display: block;
            margin: 0;
            padding: 0;

            .form-control {
              height: 32px !important;
              //font-size: 18px !important;
              line-height: 1.42857143;

              color: #000 !important;
              background-color: #fff;
              border: 1px solid #fff;
              box-shadow: none;
              border-radius: 0;
              padding: 6px 12px;
            }

            .dropdown {
              top: inherit;
            }

            .btn-danger {
              color: #fff;
              background-color: $red;
              -webkit-border-radius: none;

              font-weight: normal;
              //font-size: 16px;
              text-shadow: none;
              border-radius: 0;
              width: 100%;
              border-color: $red;
              margin-top: 2px;
              height: 30px;
            }

            .input-field {
              display: none;
            }

            .input-button {
              padding: 0;
              height: 41px;
              margin-top: 17px;
            }

            .close-input {
              position: absolute;
              right: -25px;
              top: 0%;
              color: $red;
              font-size: 30px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  header {
    .language-mobile {
      display: none;
    }

    .navbar-toggle-search {
      display: none;
    }

    #mobileSearch {
      display: none;
    }

    .nav_sec {
      .navSearchEnquiries {
        span {
          right: -10px;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  header {
    .nav_sec{
      &.affix{
        .navbar-nav {
          width: calc(100% - 75px);
        }
      }
    }
    .navbar-nav {
      width:100%;
      li {
        &.auth {
          float: right;
          .dropdown-menu{
            left: unset;
            right: 0;
          }
        }
      }
    }

  }
}