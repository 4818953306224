#valuation-dialog {
    display: none;
}

#valuation-container {
    width: 100%;
    background-image: url(../img/valuation-background.png);
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 30px;

    .valuation-form {
        width: 100%;
        background-color: #ffffff;
        border-radius: 10px;
        padding: 20px;
        text-align: center;
        position: relative;

        .loader {
            width: 100%;
            height: 100%;
            background-color: #EFEFEE;
            opacity: 0.8;
            position: absolute;
            left: 0;
            top: 0;
            display: none;
            align-items: center;
            justify-content: center;
            z-index: 999;
            border-radius: 10px;

            .spinner {
                margin: 100px auto;
                width: 50px;
                height: 40px;
                text-align: center;
                font-size: 10px;

                > div {
                    background-color: #DC042B;
                    height: 100%;
                    width: 6px;
                    display: inline-block;

                    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
                    animation: sk-stretchdelay 1.2s infinite ease-in-out;
                }

                .rect2 {
                    -webkit-animation-delay: -1.1s;
                    animation-delay: -1.1s;
                }

                .rect3 {
                    -webkit-animation-delay: -1.0s;
                    animation-delay: -1.0s;
                }

                .rect4 {
                    -webkit-animation-delay: -0.9s;
                    animation-delay: -0.9s;
                }

                .rect5 {
                    -webkit-animation-delay: -0.8s;
                    animation-delay: -0.8s;
                }

                @-webkit-keyframes sk-stretchdelay {
                    0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
                    20% { -webkit-transform: scaleY(1.0) }
                }

                @keyframes sk-stretchdelay {
                    0%, 40%, 100% {
                        transform: scaleY(0.4);
                        -webkit-transform: scaleY(0.4);
                    }  20% {
                           transform: scaleY(1.0);
                           -webkit-transform: scaleY(1.0);
                       }
                }
            }
        }

        .step-2, .success, .failed {
            display: none;
        }

        h1 {
            margin: 0 0 15px;
        }

        small {
            display: block;
            margin-bottom: 15px;
        }

        input {
            width: 100%;
            height: 45px;
            border: 1px solid #DEDEDE;
            border-radius: 5px;
            margin-bottom: 17px;
            outline: 0;
            padding-left: 15px;
            padding-right: 15px;
            font-size: 14px;
        }

        button {
            width: 100%;
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #ffffff;
            border-radius: 5px;
            box-shadow: 0 3px 3px rgba(0, 0, 0, 0.16);
            background-color: #D7042A;
            border: 0;
        }

    }
}

@media (min-width: 767px) {
    #valuation-dialog {
        width: 320px;
        height: 115px;
        background-color: #DC042B;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16);
        position: fixed;
        z-index: 999;
        bottom: 0;
        right: 230px;
        color: #ffffff;
        cursor: pointer;
        display: block;

        .header {
            width: 100%;
            height: 28px;

            .valuation-close {
                width: 39px;
                height: 100%;
                float: right;
                background-color: rgba(255, 255, 255, 0.3);
                text-align: center;
            }
        }

        a {
            color: #ffffff;
            text-decoration: none;

            .content {
                padding-left: 35px;
                padding-right: 35px;
                text-align: center;

                h4 {
                    margin-bottom: 6px;
                }
            }
        }
    }

    #valuation-container {
        padding-top: 100px;
        padding-bottom: 100px;

        .valuation-form {
            max-width: 550px;
            padding: 50px 44px;

            h1 {
                font-size: 40px;
                margin-bottom: 25px;
            }

            small {
                font-size: 18px;
                margin-bottom: 25px;
            }

            input {
                height: 56px;
            }

            button {
                height: 56px;
            }

        }
    }
}

@media (min-width: 1024px) {
    #valuation-container {
        padding-top: 150px;
        padding-bottom: 150px;
    }
}