#homepage {
  .payoff-wrapper {
    background-color: $light-grey2;
    padding: 40px 0;

    h1 {
      color: $red;
      text-align: center;
      margin: 0;
      //font-size: 36px;
      position: relative;
    }
  }

  .wrapper_grey {
    background: $light-grey2;
    padding-bottom: 15px;

    h2 {
      margin-bottom: 20px;
    }
  }

  .wrapper_white {
    background: #fff;
    margin-bottom: 20px;

    h2 {
      margin-bottom: 20px;
    }
  }
  .expected-item-row {
    .row {
      margin-left: 0;
      margin-right: 0;
      display: flex;
      flex-wrap: wrap;

      .col-xs-12 {
        padding-left: 5px;
        padding-right: 5px;
        display: flex;
        flex-direction: column;
      }
    }

    .expected-item {
      height: 100%;
      border: #e0e9e9 1px solid;
      background-color: #fff;
      margin-bottom: 15px;
      position: relative;

      .overlay {
        background-color: rgba(221,4,43,.8);
        color: #fff;
        padding: 5px;
        position: absolute;
        top: 0;
        text-align: center;
        left: 50%;
        width: 100%;
        transform: translate(-50%);
        text-transform: uppercase;
      }

      &:hover {
        border: #f2f5f6 1px solid;
        background-color: #d8282f;

        .caption {
          .price {
            color: #ffffff;
          }
        }
      }

      img {
        display: block;
        width: 100%;
        height: auto;
      }

      h4 {
        font-size: 18px;
        margin: 0;
        line-height: 24px;
        font-weight: normal;
        color: #32383d;
      }

      a:hover, a:active, a:visited {
        text-decoration: none;
      }

      .caption {
        padding: 15px 0 5px 20px;

        .price {
          font-size: 14px;
          font-family: "Merriweather", serif;
          color: #d8282f;
          margin-top: 15px;
        }
      }
    }
  }

  .expected-item-button {
    padding: 10px 20px !important;
    background: #d8282f;
    border-radius: 0;
    border: none;
  }

  .content-wrapper {
    padding: 0 0 20px;

    a {
      color: $red;
      text-decoration: none;

      &:hover {
        text-decoration: none;

        .icon-arrow487 {
          color: $red
        }
      }
    }

    h2 {
      //font-size: 20px;
      //line-height: 50px;
      margin: 20px 0;
      //text-transform: uppercase;
    }

    .name-wrapper {
      line-height: 0.5;
      padding-left: 0;

      #name {
        font-size: 23px;
        margin-top: 10px;
      }
    }

    #social-buttons {
      cursor: pointer;
      padding: 0;

      .icon-facebook55 {
        height: 35px;
        width: 35px;
        background-color: #015db4;
        color: white;
        display: inline-block;
        font-size: 14px;
        line-height: 2.5;
        text-align: center;
        padding-left: 6px;
      }

      .icon-linkedin11 {
        height: 35px;
        width: 35px;
        background-color: #015db4;
        color: white;
        display: inline-block;
        font-size: 14px;
        line-height: 2.5;
        text-align: center;
        padding-left: 6px;
      }

      .icon-instagram {
        height: 35px;
        width: 35px;
        background-color: #015db4;
        color: white;
        display: inline-block;
        font-size: 14px;
        line-height: 2.5;
        text-align: center;
        padding-left: 6px;
      }
    }

    #maak-kennis {
      margin-top: 10px;

      a {
        position: relative;
        //padding-left: 20px;

        .icon-arrow487 {
          position: absolute;
          top: 2px;
          left: 0;

          line-height: 1.5;
          font-size: 10px;
          width: 16px;
          height: 16px;
          border-radius: 15px;
          border: 1px solid $red;
          text-align: center;

          &:before {
            margin-left: 2px;
          }
        }
      }
    }

    ul {

      padding: 0;
      list-style: none;

      li {
        position: relative;
        line-height: 2;
        font-weight: 600;
        padding-left: 20px;

        .icon-arrow487 {
          position: absolute;
          top: 7px;
          left: 0;

          line-height: 1.5;
          font-size: 10px;
          width: 16px;
          height: 16px;
          border-radius: 15px;
          border: 1px solid $red;
          text-align: center;

          &:before {
            margin-left: 2px;
          }
        }
      }
    }

    .leftimg {
      width: 100%;
      max-width: 300px;
      height: auto;
    }

    .rightimg {
      width: 100%;
      max-width: 100px;
      height: auto;
    }

    .btn-danger {
      color: #fff;
      background-color: #32383d;
      -webkit-border-radius: 0;

      font-weight: normal;
      font-size: 26px;
      text-shadow: none;
      border-radius: 0;
      line-height: 58px;
      margin-top: 40px;
      border-color: #32383d;
      padding: 0 50px;
    }
  }
}