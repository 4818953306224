@keyframes fill-green {
    from {
        background-color: #ffffff;
    }
    to {
        color: #ffffff;
        background-color: #349E30;
        border: none;
    }
}

@keyframes change-color-green {
    to {
        color: #349E30;
        
    }
}

@keyframes fill-blue {
    from {
        background-color: #ffffff;
    }

    to {
        color: #ffffff;
        background-color: $new-red;
    }
}

@keyframes change-color-blue {
    to {
        color: $new-red;
    }
}

@keyframes change-check {
    to {
        content: "\e90b";
        font-size: 11px;
        font-family: 'icomoon';
    }
}

@keyframes slide-left-1 {
    to {
        transform: translateX(calc(-100% - 38px));
    }
}

@keyframes slide-left-2 {
    to {
        transform: translateX(calc(-200% - 76px));
    }
}

@keyframes slide-left-end {
    to {
        transform: translateX(calc(-300% - 114px));
    }
}

@keyframes hide {
    to {
        visibility: hidden;
        height: 0;
    }
}