#register {
  .panel {
    .panel-body {
      padding: 30px 30px 50px;

      label {
        width: 100%;

        i {
          float: right;
          margin-top: 3px;
        }
      }

      svg {
        height: 80px;

        path {
          fill: $red;
        }
      }

      h2 {
        margin: 30px 0 20px 0;
      }

      .btn {
        margin-bottom: 20px;
      }
    }
  }
}

.wrapper {
  padding: 45px 0;

  &.wrapper-gray {
    background: #f2f2f2;
  }
}

// ***************************************************************** 
// SM
// ***************************************************************** 

@media (min-width: $screen-sm-min) {
}

// ***************************************************************** 
// MD
// ***************************************************************** 

@media (min-width: $screen-md-min) {
}

// ***************************************************************** 
// LG
// ***************************************************************** 

@media (min-width: $screen-lg-min) {
}