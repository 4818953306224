#search-enquiries{
    .btn-red{
        background-color: $new-red;
        color: white;
        border-radius: 0;
        padding: 15px 20px;
        font-size: 16px;
    }

    padding: 0;
    #search-enquiries-header{
        
        padding: 30px 0px;
        border-top: 10px solid $new-red;
        height: 400px;
        margin-top: 0;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1)), url('/img/search-enquiries-header.png'), no-repeat;
        color: white;
        .header-content{
            width: 60%;
            p{
                font-weight: 300;
            }
           
        }
    }
    main{
        padding: 0;
        #counter-filter{
            width: 100%;


            h2{
           
           
            }
            .balk{
                background-color: #fff;
                padding: 20px 0px;
                .container{
                    position: relative;
                    #filter-button{
                     
                    }
                    #filter-panel{
                        position: absolute;
                        
                        background-color: #32383D;
                        padding: 10px;
                        z-index: 9;
                        right: 15px;
                        top: 120%;
                        box-shadow: 0px 5px 10px #000033;
                        form{
                            width: 100%;
                            background-color: #32383D;
                            select{
                                border-radius: 0px;
                                height: 50px;
                                font-size: 14px;
                                padding-left: 15px;
                            }
                            label{
                                color: white;
                            }
                            .price{
                                width: 100%;
                                select{
                                    width: 50%;
                                }
                            }
                            button{
                                width: 100%;
                                padding: 10px 20px;
                                margin: 5px 0px 0px;
                            }
                        }
                    }
                }
               
            }
            
           
            
            
        }
    }
   
    #object-container{
        background-color: #F5F5F5;

        .object-wrapper{
            padding: 20px 20px;
            .object{
                
                .inside-object{
                    background-color: white;
                    
                    a{
                        &:hover{
                            text-decoration: none;
                        }
                        .content{
                            min-height: 300px;
                            max-height: 300px;
                            padding: 15px 20px;
                            color: black;   
                    
                            h3{
                                font-weight: bold;
                                font-size: 18px;
                            }
                            .price{
                                
                                color: #666666;
                                font-size: 14px;
                                padding: 5px 10px;
                                background-color:#E0E1E2;
                            }
                            .more{
                                text-decoration: underline;
                                color: #24387C;
                            }
                            p{
                                font-size: 16px;
                                margin: 10px 0px
                            }
                            ul{
                                margin-top: 10px;
                                padding: 0;
                                list-style-type: none;
                                
                                li{
                                    color: #666666;
                                    font-size: 14px;
                                    i{
                                        font-size: 14px !important;
                                        margin-right: 14px;

                                        &.icon-bed{
                                            margin-right: 8px;
                                        }
                                    }
                                
                                    
                                   

                                }
                            }
                        }
                       
                    }
                    .footer{
                        padding: 20px;
                        border-top: 2px solid #E0E1E2;
                        border-style: dotted none none none;
                        font-size: 14px;
                        .btn-red{
                            margin-top: 10px;
                            padding: 10px 15px;
                        }
                    }
                }
                
            }
        }

    }
}
@media (max-width: $screen-xs-min), (min-width: $screen-xs-min){
    #counter-filter{
        
        .balk{
            .container{
                h2{
                    
                    font-size: 16px;
                }
               
                #filter-panel{
         
                 
                    height: fit-content;
                    
                    
                } 
            }
        }
        
    }
    .object{
        .footer{
            .btn-red{
                display: block;
            }
        }
    }
}
@media (max-width: $screen-sm-min) {
    #counter-filter{
        .balk{
            border-top: 2px solid $new-red;
            .container{
            
               
                #filter-panel{
                    width: 100%;
                   left: 0;
                    height: fit-content;
                    
                    
                } 
            }
        }
        
    }
}
@media (min-width: $screen-sm-min) {
    #counter-filter{
        .balk{
            .container{
                h2{
                    float: left;
                    font-size: 25px;
                }
                #filter-button{
                  
                    float: right;
                }
                #filter-panel{
                    
                    
                    width: 750px;
                    height: 250px;
                } 
            }
        }
        
    }
    .object{
        .footer{
            .btn-red{
                display: inline-block;
            }
        }
    }
}