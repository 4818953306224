#search-enquiries-offer-header{
    padding: 30px 0px;
    border-top: 10px solid $new-red;
    height: 400px;
    margin-top: 0;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1)), url('/img/search-enquiries-header.png'), no-repeat;
    color: white;
    .container{
        height: 100%;
        position: relative;
        .header-content{
            
            position: absolute;
            top: 30%;
            width: 60%;
            p{
                font-weight: 300;
            }
        }
    }
    
}

#offer-object-container {
   

    #form {
        @extend %modal;

        padding: 30px;
        

        
        input, textarea {
            @extend %input;

            margin-bottom: 15px;
        }

        .checkbox-container {
            width: 100%;
            border: 1px solid rgba(36, 56, 124, 0.1);
            box-shadow: 0 2px 5px rgba(36, 56, 124, 0.1);
            padding: 16px 20px;
            margin-bottom: 15px;

            @extend %checkbox-input;
        }

        button {
            @extend %button;

            margin-left: auto;
        }
    }

    .object {
        margin: 0 -15px;
        margin-bottom: 30px;
      
        .inside-object{
            background-color: white;
            border-radius: 5px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            
            a{
                &:hover{
                    text-decoration: none;
                }
                .content{
                    min-height: 300px;
                    max-height: 300px;
                    padding: 15px 20px;
                    color: black;   
            
                    h3{
                        font-weight: bold;
                        font-size: 18px;
                    }
                    .price{
                        
                        color: #666666;
                        font-size: 14px;
                        padding: 5px 10px;
                        background-color:#E0E1E2;
                    }
                    .more{
                        text-decoration: underline;
                        color: #24387C;
                    }
                    p{
                        font-size: 16px;
                        margin: 10px 0px
                    }
                    ul{
                        margin-top: 10px;
                        padding: 0;
                        list-style-type: none;
                        
                        li{
                            color: #666666;
                            font-size: 14px;
                            i{
                                font-size: 14px !important;
                                margin-right: 14px;

                                &.icon-bed{
                                    margin-right: 8px;
                                }
                            }
                        
                            
                           

                        }
                    }
                }
               
            }
        }
            .footer{
                padding: 20px;
                border-top: 2px solid #E0E1E2;
                border-style: dotted none none none;
                font-size: 14px;
                .btn-red{
                    margin-top: 10px;
                    padding: 10px 15px;
                }
            }

       
    }
}

@media (min-width: $screen-xs-min) {
    #offer-object-container {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
       

        #form {
            .btn-red{
                float: right;
            }
        }
        .object{
            padding-top: 30px;

            .footer{
                .btn-red{
                
                    display: block;
                }
            }
        }
        
    }
}

@media(min-width: $screen-lg-min) {
    #offer-object-container {

        #form {
           
            .next-to-each-other {
              

                > div {
                    width: 50%;
                    margin-right: 30px;

                    &:last-of-type {
                        margin-right: 0;
                    }
                }
            }

            .street {
                > div:first-child {
                    width: 70%;
                    flex-shrink: 0;
                }
            }

            input {
                height: 50px;
            }

            textarea {
                height: 125px;
            }
        }

        .object {
            padding-top: 0;
           padding-left: 20px;
           
        }
    }
}