body {
  overflow-x: hidden;
}

h1, h2, h3, h4 {
  font-family: $font-family-serif;
  font-weight: 700;
}

p {

}

a {
  color: $red;
  text-decoration: none;

  &:hover {
    color: darken($red, 15%);
  }
}

.container {
  width: inherit;
  max-width: 1300px;
}

.col-xs-5ths, .col-sm-5ths, .col-md-5ths, .col-lg-5ths {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.col-xs-5ths {
  width: 20%;
  float: left;
}

.no-padding {
  padding: 0;
}

//::-moz-placeholder {  /* Firefox 19+ */
//    color: #fff;
//}

//.form-control::-moz-placeholder {
//    color:#fff;
//    opacity:1;
//}

.reference-wrapper {
  background: #dc042b;
  margin: 0;
  padding: 40px 0 30px;

  p {
    color: #fff;
    margin: 0 5px 30px;
  }

  .icon_text {
    min-height: 200px;

    img {
      margin: 20px auto;
      max-width: 70%;
    }

    .caption {
      position: absolute;
      top: 0;
      left: 0;
      width: auto;
      text-align: center;

      h1 {
        font-weight: normal;
        font-size: 65px;
        color: #fff;
        text-align: center;
        padding-left: 0 !important;
      }
    }

    .stars {
      text-align: center;

      .inner {
        display: inline-block;
        position: relative;
        width: 116px;

        .visible {
          position: absolute;
          left: 0;
          overflow: hidden;
          white-space: nowrap;

          .star {
            display: inline-block;
            width: 20px;

            img {
              max-width: 100%;
            }
          }
        }
      }
    }

    span {
      font-family: $font-family-serif;
      color: #fff;
      text-align: center;
      margin: 0;
    }
  }

  .owl-theme .owl-controls .owl-page span {
    background: #fff;
  }
}

.red_form_sec {
  float: left;
  width: 100%;
  background-color: $red;
  margin: 0;
  padding: 20px 0;

  label {
    display: none !important;
  }

  &.side {
    h1 {
      color: #fff;
      margin-left: 5px;
      margin-bottom: 10px;
    }

    .input-group {
      left: 5% !important;
      width: calc(100% - 40px) !important;
    }

    .checkbox-label {
      margin-left: 8px;
      color: #fff;
    }
  }

  .input-group {
    left: 0 !important;
    width: calc(100% - 30px) !important;
  }

  h2 {
    color: #fff;
    //line-height: 70px;
    margin: 0 0 25px;
  }

  .input-group-addon {
    display: none;
  }

  .form-control {
    //height: 45px;
    line-height: 1.42857143;

    background-color: #fff;
    border: 1px solid #999;
    box-shadow: none;
    margin-right: 25px;
    border-radius: 0;
    padding: 6px 12px;

    &::-moz-placeholder {
      color: $dark-grey;
    }
  }

  textarea.form-control {
    height: 148px;
    padding-top: 6px;
  }

  .g-recaptcha {
    margin-bottom: 5px;
  }

  .btn-danger {
    color: #fff;
    background-color: #32383d;
    -webkit-border-radius: none;

    font-weight: normal;
    font-size: 18px;
    text-shadow: none;
    border-radius: 0;
    line-height: 48px;
    border-color: #32383d;
    padding: 0 20px;
  }

  .tabs-holder {
    width: 100%;

    nav {
      .tabs {
        margin: 0;
        padding: 0;

        .tab {
          display: inline-block;
          cursor: pointer;
          border-right: 4px solid $red;
          font-size: 18px;
          line-height: 60px;
          color: #fff;
          text-align: center;
          width: 33.33%;
          float: left;
          background: #e64360;
          transition: all 300ms ease;

          &:last-child {
            border: none;
          }

          &.active,
          &:hover {
            color: $red;
          }

          &.active {
            background: #fff;
          }

          &:hover:not(.active) {
            background: #f3f3f3;
          }
        }
      }
    }

    .tab-contents {
      overflow: hidden;
      float: left;
      width: 100%;

      .tab-content {
        display: none;
        background: #fff;
        padding: 15px;

        > .row {
          margin: 0 !important;
        }

        &.active {
          display: block;
        }

        .form-row {
          .form-control {
            &::-moz-placeholder {
              color: #ccc;
            }
          }

          &.has-error {
            .form-control {
              border-color: $red;
            }
          }
        }
      }
    }
  }
}

#FrmNieuwsbrief {
  .btn {
    background: #fff;
  }
}

.btn.btn-grey {
  margin: 15px 0;
  border-radius: 0;
  background: $dark-grey;
  color: #fff;
  font-size: 18px;
  padding: 10px 20px;
}

section {
  .red_form_sec {
    .checkbox-label {
      display: block !important;
      font-weight: normal;
      margin-left: -30px;
      margin-bottom: 20px;

      input {
        margin-right: 5px;
      }
    }
  }

  .buttons-wrapper {
    .container {
      a {
        margin-top: 30px;
        width: 100%;
        white-space: normal;
        position: relative;

        .text-wrapper {
          span {
            display: block;
          }

          .caps {
            font-size: 17px;
            margin-top: 10px;
            margin-bottom: 10px;

            &.small {
              font-size: 17px;
              margin-top: 10px;
              margin-bottom: 10px;
            }

            &.big {
              font-size: 17px;
              margin-top: 0;
              margin-bottom: 10px;
            }
          }

          .small {
            font-size: 13px;
            margin-top: 0;
          }
        }

        .icon-wrapper {
          span {
            vertical-align: -35px;
            font-size: 35px;
          }
        }
      }
    }
  }
}

section {
  .content_sec {
    a {
      &.btn-danger {
        font-size: 20px !important;
        padding: 10px !important;
        background: $red;
        border: none;
        color: #FFF;
      }
    }
  }

  .inner_slider_sec {
    .slider_caption {
      .caption {
        .form-control {
          color: #ccc !important;
        }
      }
    }
  }
}

section {
  .slider_sec {
    .slider_caption {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 18%;

      .caption {
        width: 100%;
        max-width: 1000px;
        text-align: left;
        margin: 0 auto;
        padding: 0;

        h1 {
          width: 100%;
          float: left;

          font-weight: normal;
          font-size: 40px;
          color: #fff;
          text-shadow: -1px 1px 10px rgba(0, 3, 3, 0.75);
          margin: 0 0 20px;
          padding: 0;
        }

        .btn-danger {
          color: #fff;
          background-color: $red;
          -webkit-border-radius: none;

          font-size: 60px;
          text-shadow: none;
          border-radius: 0;
          border-color: $red;
          padding: 5px 20px;

          &:hover {
            color: #f66;
            background-color: #fff;
            border-color: #fff;
          }
        }
      }
    }
  }

  .tab_details {
    .nav-tabs {
      border-bottom: 1px solid #c1c3c5;
      font-weight: normal;
      //font-size: 24px;
      line-height: 54px;
    }

    .nav-tabs > li {
      float: left;
    }

    .nav-tabs > li > a {
      font-family: $font-family-serif;
      margin-right: 2px;
      font-size: 20px;
      color: #31383d;
      cursor: pointer;

      &:hover {
        background-color: #fff;
        border-bottom: none;
        color: $red;
        border-color: #fff;
      }
    }

    .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
      color: $red;
      cursor: default;
      background-color: #fff;
      border-bottom: $red 6px solid;
      border-color: transparent;
    }

    .tab-content > .contact_area > .active {
      display: block;
    }

    .contact_area {
      padding: 50px 0;

      h4 {

        //font-weight: normal;
        //font-size: 20px;
        color: #31383d;
        line-height: 30px;
        margin: 0;
      }

      .row_border {
        border-bottom: #e5ebed 1px solid;
        margin-bottom: 15px;
        padding-bottom: 15px;
      }

      .list_name {
        color: #31383d;
        line-height: 30px;
      }
    }

  }

  .photo_sec {

    h1 {

      font-weight: normal;
      font-size: 44px;
      color: $red;
      line-height: 70px;
      margin: 0 0 25px;
    }

    .col_pd {
      padding: .5px;
    }
  }

  .btn_sec {

    .btn-danger {
      color: #fff;
      background-color: #32383d;
      -webkit-border-radius: none;

      font-weight: normal;
      font-size: 26px;
      text-shadow: none;
      border-radius: 0;
      width: 100%;
      border-color: #32383d;
      padding: 5px 0;

      &:hover {
        color: #fff;
        background-color: $red;
        border-color: $red;
      }
    }

    .icon_sec {
      float: right;
      margin: 8px 0;

      .icon_md {
        margin: 0 1px;
      }
    }
  }

  .to_top {
    position: fixed;
    top: 70%;
    right: 0px;
    z-index: 999;

    .button_top {
      background-color: $red;
      z-index: 2;
      color: #fff;
      border: none;
      float: left;
      height: 50px;
      width: 25px;
      margin-top: 0;
      padding: 0;

      span {
        color: #fff;

        &::before {
          transform: rotate(270deg);
          display: block;
        }
      }
    }
  }

  .box_i_sec {
    position: fixed;
    top: 25%;
    right: -270px;
    overflow: hidden;
    z-index: 999;

    .box_i_btn {
      background-color: $red;
      z-index: 2;
      color: #fff;
      border: none;
      float: left;
      margin-top: 20px;
      padding: 15px 10px;
    }

    .box {
      float: left;
      width: 270px;
      background-color: $red;
      padding: 0 30px;

      h2 {
        //font-size: 20px;
        color: #fff;
        line-height: 90px;
        margin: 0;
      }

      .input-group {
        left: 0 !important;
        width: 100% !important;
      }

      label {
        display: none;
      }

      .form-control {
        width: 100%;
        height: 40px;
        line-height: 1.42857143;

        color: #000;
        background-color: #FFF;
        border: 1px solid #e64360;
        box-shadow: none;
        margin-bottom: 15px;
        border-radius: 0;
        padding: 6px 12px;

        .input-placeholder {
          color: #000;
        }
      }

      .input-group-addon {
        display: none;
      }

      .g-recaptcha {
        transform: scale(0.7);
        -webkit-transform: scale(0.7);
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
      }

      textarea {
        &.form-control {
          height: 120px !important;
        }
      }

      .dropdown-menu {
        border-radius: 0;

        font-size: 20px;
        color: #fff;
        margin: 0;
      }

      .btn-danger {
        color: #fff;
        background-color: #32383d;
        -webkit-border-radius: none;

        font-weight: normal;
        font-size: 18px;
        text-shadow: none;
        border-radius: 0;
        line-height: 48px;
        width: 100%;
        margin-bottom: 20px;
        border-color: #32383d;
        padding: 0;
      }
    }

  }

  .red_title_sec {
    float: left;
    width: 100%;
    background-color: $red;
    margin: 0;
    padding: 0;

    h1 {

      font-weight: normal;
      color: #fff;
      font-size: 45px;
      line-height: 120px;
      margin: 35px 0 0;
    }

    ul {
      &.icon_link {
        float: right;
        margin: 0;
        padding: 0;

        li {
          display: inline-block;
          margin: 0;
          padding: 0;

          a {
            i {
              width: 52px;
              font-size: 24px;
              color: #fff;
              text-align: center;
              display: block;
              margin: 0;
              padding: 91px 0 40px;
            }
          }
        }
      }
    }

    .dropdown_small {
      float: right;
      margin: 88px 60px 0 0;

      .btn-primary {
        color: #fff;
        background-color: transparent !important;
        width: 100%;
        border-radius: none;
        -webkit-border-radius: none;

        font-weight: normal;
        font-size: 24px;
        border-color: transparent;

        &:hover {
          color: #ddd2ac;
          border-color: transparent;
        }
      }

      .dropdown-menu {
        border-radius: 0;

        font-size: 18px;
        background-color: #fff;
        border: #fff 1px solid;
        margin: 2px 0 0;
        padding: 0;
      }

      .dropdown-menu > li > a {
        color: #31383d;
        line-height: 40px;
      }
    }
  }

  .view-tiles {
    float: left;
    width: 100%;
    margin: 0;
    padding: 10px 0;

    .col_pd {
      padding-bottom: 10px;
    }
  }

  .pagination {
    width: 100%;
    float: left;
    text-align: center;
    margin: 30px 0 0;
    padding: 0;

    ul {
      width: 100%;
      float: left;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        height: 40px;
        line-height: 40px;
        width: 40px;

        a, span {
          height: 40px;
          line-height: 40px;
          color: #31383d;

          font-weight: normal;
          text-decoration: none;
          margin: 0;
          padding: 0;
          border: 0;
          float: unset;
          position: unset;

          &:hover {
            color: #fff;
            background-color: $red;
            height: 40px;
            line-height: 40px;
            padding: 7px 15px;
          }
        }
        &.active {
          a, span {
            color: #fff;
            background-color: $red;
            height: 40px;
            line-height: 40px;
            padding: 7px 15px;
            border: 0;
            float: unset;
            position: unset;
          }
        }
      }
    }

  }

  .red_text_sec {
    float: left;
    width: 100%;
    background-color: $red;
    margin: 0;
    padding: 3px 0;

    h1 {

      font-weight: normal;
      color: #fff;
      font-size: 30px;
      line-height: 40px;
      margin: 0;
    }

    .btn-danger {
      color: #fff;
      background-color: #32383d;
      -webkit-border-radius: none;

      font-weight: normal;
      font-size: 26px;
      text-shadow: none;
      border-radius: 0;
      line-height: 58px;
      margin-top: 40px;
      border-color: #32383d;
      padding: 0 50px;
    }
  }

  .content_sec {
    h1 {

    }

    p {
      font-size: 16px;
      line-height: 26px;
      margin: 0 0 20px;
    }

    .bold_text {

      font-weight: normal;
      font-size: 22px;
      line-height: 32px;
      color: #31383d;
      margin: 0 0 30px;
    }

    a {
      color: $red;
      text-decoration: none;
    }

    ul {
      list-style: none;
      margin: 0 0 0 20px;
      padding: 0;
    }

    li {
      list-style-image: url(../img/li_icon.jpg);
      color: #31383d;
    }

    .black_box {
      background-color: #31393d;
      text-align: center;
      margin-top: 40px;
      padding: 25px 0;

      .text_reg {

        color: #fff;
        font-size: 42px;
        line-height: 48px;

        span {

          font-weight: normal;
          font-size: 42px;
          line-height: 48px;
          color: #fff;
        }
      }
    }

    .rightside {
      margin: 0;
      padding: 25px 0 0 0;

      h4 {
        margin-top: 0;
      }

      .gray_box {
        background-color: #84888b;
        margin-bottom: 40px;
        padding: 50px 25px;

        h2 {

          font-weight: normal;
          color: #fff;
          font-size: 28px;
          line-height: 36px;
          margin: 0 0 70px;
        }

        p {

          color: #fff;
          font-size: 22px;
          line-height: 24px;
          margin: 0;
        }
      }

      .photo_box {
        border: #e0e9e9 1px solid;
        background-color: #fff;
        margin-bottom: 30px;
        position: relative;
        .title {
          position: relative;
          padding: 25px 25px 0 25px;
        }
        h2 {
          font-weight: normal;
          color: #31393d;
          font-size: 18px;
          line-height: 36px;
          margin: 0 0 25px 0;
          padding: 0;
          a {
            color: #31393d;

            font-weight: normal;
            &:hover {
              color: $red;
            }
          }
        }

        p {

          color: #31393d;
          font-size: 22px;
          line-height: 24px;
          margin: 0 0 25px 0;
          padding: 0;
          a {
            color: #31393d;
            &:hover {
              color: $red;
            }
          }
        }
      }
    }
  }

  .grid_box_side {
    .embed-responsive {
      .label {
        float: left;
        position: absolute;
        left: 6px;
        top: 6px;
        z-index: 1000;
        background: $red;
        width: 200px;
        height: 37px;

        font-weight: normal;
        color: #fff;
        font-size: 23px;
        border-radius: 0;
      }
    }

    .btn-danger {
      color: #fff;
      background-color: $red;
      -webkit-border-radius: none;

      font-weight: normal;
      font-size: 26px;
      text-shadow: none;
      border-radius: 0;
      width: 100%;
      line-height: 58px;
      margin-top: 30px;
      margin-bottom: 20px;
      border-color: $red;
      padding: 0;
    }
  }

  .betekenen_sec {
    .box_contant {
      h2 {

        font-weight: normal;
        color: #31383d;
        line-height: 40px;
        margin: 15px 0;
      }

      p {

        font-size: 22px;
        color: #31383d;
        line-height: 32px;
        min-height: 100px;
      }
      a {
        text-decoration: none;
        font-size: 20px;
      }

    }
  }

  .de_mensen_achter_sec {
    .item {
      .img {
        border: 1px solid #31383d;
      }

      .caption {
        p {

          font-size: 20px;
          color: #31383d;
          line-height: 30px;
          min-height: 200px;
        }
      }
    }

    .item2 {
      .img {
        border: 5px solid #31383d;
      }

      .caption {
        p {

          font-size: 10px;
          color: #31383d;
          line-height: 30px;
          min-height: 200px;
        }
      }
    }

    .btn-danger {
      color: #fff;
      background-color: $dark-grey;
      -webkit-border-radius: none;

      //font-weight: normal;
      font-size: 18px;
      text-shadow: none;
      border-radius: 0;
      line-height: 48px;
      margin-top: 30px;
      margin-bottom: 20px;
      border-color: $dark-grey;
      padding: 0 40px;
    }
  }

  .inner_slider_sec {
    .carousel {
      .carousel-inner {
        .item {
          &.active {
            .scrolldown-icon {
              position: absolute;
              bottom: 5%;
              left: 50%;
              color: #FFF;
              border: 2px solid #FFF;
              border-radius: 50%;
              height: 50px;
              width: 50px;
              line-height: 50px;
              text-align: center;
              cursor: pointer;
              font-size: 25px;
              transform: translate(-50%);
            }
          }
        }
      }
    }
    .slider_caption {
      position: absolute;
      left: 0;
      right: 0px;
      bottom: 50%;
      height: 0%;
      width: 100%;

      .caption {
        width: 100%;
        max-width: 1250px;
        text-align: left;
        margin: 0 auto;
        padding: 0;

        .row_md {
          margin-top: 120px;
        }

        .title {
          width: 100%;
          float: left;

          font-weight: bold;
          font-size: 80px;
          color: #fff;
          text-shadow: -1px 1px 10px rgba(0, 3, 3, 0.75);
          padding: 0;
        }

        h1 {
          width: 100%;
          float: left;

          font-weight: bold;
          font-size: 40px;
          color: #fff;
          text-shadow: -1px 1px 10px rgba(0, 3, 3, 0.75);
          margin: 0 0 20px;
          padding: 0;
        }

        .form-control {
          width: 100%;
          height: 52px;
          line-height: 1.42857143;

          color: #000 !important;
          background-color: #fff;
          border: 1px solid #fff;
          box-shadow: none;
          border-radius: 0;
          padding: 6px 12px;
        }

        .btn-danger {
          color: #fff;
          background-color: $red;
          -webkit-border-radius: none;

          font-weight: normal;
          font-size: 26px;
          text-shadow: none;
          border-radius: 0;
          width: 100%;
          border-color: $red;
        }

        .box {
          width: 74px;
          float: left;
          margin-right: 16px;

          font-size: 20px;
          color: #fff;
          text-align: center;
        }

        .red_sec_sm {
          background: url(../img/red_arrow_sm.png) bottom center no-repeat;
          height: 160px;
          width: 74px;
          text-align: center;
          float: left;
          line-height: 30px;

          font-weight: normal;
          font-size: 38px;
          color: #fff;
          padding-top: 95px;
        }

        .red_sec_lg {
          background: url(../img/red_arrow_lg.png) bottom center no-repeat;
          height: 160px;
          width: 74px;
          text-align: center;
          float: left;
          line-height: 30px;

          font-weight: normal;
          font-size: 38px;
          color: #fff;
          padding-top: 95px;
        }

        .black_sec_sm {
          background: url(../img/black_arrow_sm.png) bottom center no-repeat;
          height: 160px;
          width: 74px;
          text-align: center;
          float: left;
          line-height: 30px;

          font-weight: normal;
          font-size: 38px;
          color: #fff;
          padding-top: 95px;
        }

        .black_sec_lg {
          background: url(../img/black_arrow_lg.png) bottom center no-repeat;
          height: 160px;
          width: 74px;
          text-align: center;
          float: left;
          line-height: 30px;

          font-weight: normal;
          font-size: 38px;
          color: #fff;
          padding-top: 95px;
        }

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          max-width: 40%;
        }
      }
    }

    .carousel-control {
      opacity: 1;
      width: 4%;
    }
  }

  .slider_sec, .inner_slider_sec {
    width: 100%;
    float: left;
    padding-top: 0;
    margin: 0;
  }

}

.carousel-indicators {
  li {
    background-color: #fff;
    border: 1px solid #fff;
    cursor: pointer;
    margin-top: 5px !important;
    text-indent: -999px;
    width: 12px;
    height: 12px;
  }

  .active {
    background-color: $red !important;
    height: 14px;
    width: 14px;
    border-color: $red;
    margin: 0;
  }
}

#owl-demo {
  .item {
    margin: 5px;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}

#owl-demo2 {
  .item {
    img {
      display: block;
      width: 100%;
      height: auto;
      border: 1px solid #84888b;
    }
  }
}

#owl-mensen {
  .item {
    img {
      display: block;
      width: auto;
      height: auto;
      border: 1px solid #84888b;
    }
  }
}

.column-content-area {
  .caption {
    h2 {
      font-size: 26px;
      font-weight: normal;
      margin: 10px 0 5px;
    }

    p {
      min-height: 200px;
    }
  }
}

.footer-form {
  .btn-group.bootstrap-select.form-control, .form-control {
    margin-bottom: 12px;
    height: 60px !important;
  }

  .btn.dropdown-toggle.btn-default {
    height: 60px !important;
  }
}

.carousel-control {
  z-index: 333;

  .icon-next, .glyphicon-chevron-right {
    margin-right: -5px !important;
  }
}

.form-control:-ms-input-placeholder, .form-control::-webkit-input-placeholder {
  color: #fff;
}

header .nav_sec .navbar-default .brand_small, #header.affix .navbar-brand, section .tab_details .tab-content > .contact_area > .tab-pane {
  display: none;
}

header .nav_sec .navbar-default .navbar-nav > .active > a, header .nav_sec .navbar-default .navbar-nav > .active > a:hover, header .nav_sec .navbar-default .navbar-nav > .active > a:focus, header .nav_sec .navbar-default .navbar-nav > li > a:hover, header .nav_sec .navbar-default .navbar-nav > li > a:focus, header .nav_sec .navbar-default .navbar-nav > .open > a, header .nav_sec .navbar-default .navbar-nav > .open > a:hover, header .nav_sec .navbar-default .navbar-nav > .open > a:focus {
  background: none;
  color: $red;
}

section .slider_sec .carousel-inner > .item > img, section .slider_sec .carousel-inner > .item > a > img, section .slider_sec .carousel-inner > .item > a > img, section .inner_slider_sec .carousel-inner > .item > img {
  width: 100%;
}

section .slider_sec .carousel-indicators, section .inner_slider_sec .carousel-indicators {
  margin-bottom: 0;
}

section .slider_sec .slider_caption .caption h1:first-child, section .inner_slider_sec .slider_caption .caption h1:first-child {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

section .slider_sec .slider_caption .caption button:nth-child(2), section .inner_slider_sec .slider_caption .caption h1:nth-child(2) {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

section .tab_details .contact_area .glyph-icon, section .betekenen_sec .glyph-icon {
  display: block;
  font-family: Flaticon;
  font-size: 55px;
  line-height: 1;
  color: #e22a4b;
  padding: 0;
}

section .photo_sec, section .map_sec, section .inner_photo, section .video_sec, section .view360_sec {
  float: left;
  width: 100%;
  margin: 0;
  padding: 0;
}

section .inner_photo .carousel .carousel-inner img {
  margin: 0 auto;
  width: 100%;
}

section .photo_sec, section .video_sec, section .view360_sec {
  margin-bottom: 60px;
}

section .photo_sec .row_md, section .grid_box .row_md {
  margin: 0;
}

section .red_form_sec .btn-danger:hover, section .box_i_sec .box .btn-danger:hover, section .red_text_sec .btn-danger:hover, section .photo_text_sec .btn-danger:hover {
  color: $red;
  background-color: #fff;
  border-color: #fff;
}

section .contact_sec h2, section .content_sec h2 {

  font-weight: normal;
  color: #31383d;
  margin: 0 0 10px;
}

section .red_title_sec ul.icon_link li a:hover i, section .red_title_sec ul.icon_link li a.active i {
  background: #e7506b;
  border-color: #e7506b;
}

section .view-tiles .project_detalis, section .grid_box_side .item {
  border: #e0e9e9 1px solid;
  background-color: #fff;
  transition: all 300ms ease;

  * {
    transition: all 300ms ease;
  }
}

section .grid_box_side .item .label {
  display: inline-block;
  color: $red;
  //font-size: 15px;
  text-transform: uppercase;
  padding: 10px 20px !important;
  font-weight: normal;
}

section .view-tiles .project_detalis:hover, section .grid_box_side .item:hover, section .news-overview .item:hover {
  border: #f2f5f6 1px solid;
  background-color: $red;

  .caption {
    h4 {
      color: #fff;

      span {
        color: #fff;
      }
    }
  }

  * {
    color: #fff;
  }
}

section .grid_box_side .item:hover .label {
  color: #fff;
}

section .view-tiles .project_detalis:hover .caption h3, section .grid_box_side .item:hover .caption h3, section .news-overview .item:hover .caption h3,
section .view-tiles .project_detalis:hover .caption h3 span, section .grid_box_side .item:hover .caption h3 span, section .news-overview .item:hover .caption h3 span,
section .view-tiles .project_detalis:hover .caption h1, section .grid_box_side .item:hover .caption h1, section .news-overview .item:hover .caption h1 {
  color: #fff;
}

section .view-tiles .project_detalis h4, section .grid_box_side .item .caption h4 {
  font-weight: normal;
  color: #32383d;
  line-height: 28px;
  margin: 20px 0 0 20px;
  font-size: 16px;
}

section .view-tiles .project_detalis h4 span, section .grid_box_side .item .caption h4 span {
  color: #32383d;
  line-height: 28px;
  margin: 0;
}

section .view-tiles .project_detalis h4, section .grid_box_side .item .caption h4 {
  font-weight: normal;
  color: #32383d;
  line-height: 24px;
  margin: 15px 0 5px 20px;
}

.project_detalis {
  .image-wrapper {
    position: relative;
  }
  .prijs {
    font-size: 18px;
    font-family: $font-family-serif;
    color: $red;
    margin: 15px 0 5px 20px;
    min-height: 58px;
  }

  &:hover {
    .prijs {
      color: #fff;
    }
  }
}

section .grid_box_side .item {
  .caption .prijs {
    font-size: 18px;
    font-family: $font-family-serif;
    color: $red;
    margin: 15px 0 5px 20px;
  }

  &:hover {
    .caption .prijs {
      color: #fff;
    }
  }
}

.project_detalis .image-wrapper .overlay {
  background-color: rgba(221, 4, 43, 0.8);
  color: #fff;
  padding: 5px;
  position: absolute;
  top: 0;
  text-align: center;
  left: 50%;
  width: 100%;
  transform: translate(-50%);
  text-transform: uppercase;
}

section .grid_box_side .btn-danger:hover, section .de_mensen_achter_sec .btn-danger:hover, section .inner_slider_sec .slider_caption .caption .btn-danger:hover {
  color: $red;
  background-color: #32383d;
  border-color: #32383d;
}

section .grid_box_side .owl-theme .owl-controls .owl-buttons div, section .de_mensen_achter_sec .owl-theme .owl-controls .owl-buttons div {
  background-color: none;
  //font-size: 50px;
  color: #e6eced;
  background: none;
  opacity: 1;
}

section .grid_box_side .owl-theme .owl-controls .owl-buttons div.owl-prev, section .de_mensen_achter_sec .owl-theme .owl-controls .owl-buttons div.owl-prev {
  top: 50%;
  position: absolute;
  left: -3%;
}

section .grid_box_side .owl-theme .owl-controls .owl-buttons div.owl-next, section .de_mensen_achter_sec .owl-theme .owl-controls .owl-buttons div.owl-next {
  top: 50%;
  position: absolute;
  right: -3%;
}

section .betekenen_sec h1, section .de_mensen_achter_sec h1 {
  font-weight: normal;
  color: #31383d;
  //font-size: 45px;
  margin: 0 0 40px;
}

section .de_mensen_achter_sec .item .caption h2, section .de_mensen_achter_sec .item2 .caption h2 {
  font-weight: normal;
  color: #31383d;
  line-height: 40px;
  margin: 10px 0 5px;
}

section .inner_slider_sec .slider_caption .caption .box .red_sec_sm span, section .inner_slider_sec .slider_caption .caption .box .red_sec_lg span {

  font-weight: normal;
  font-size: 18px;
  color: #e66774;
}

section .inner_slider_sec .slider_caption .caption .box .black_sec_sm span, section .inner_slider_sec .slider_caption .caption .box .black_sec_lg span {

  font-weight: normal;
  font-size: 18px;
  color: #848789;
}

section .inner_slider_sec .carousel-control.left, section .inner_slider_sec .carousel-control.right {
  background-image: none;
}

#owl-demo2 .item, #owl-mensen .item {
  margin: 5px 20px;
}

.messages {

  .message {
    margin-bottom: 15px;
    padding: 10px;

    &.message-danger {
      background: tint($red, 90%);
      color: $red;
    }

    &.message-success {
      background: tint(#228b22, 90%);
      color: #228b22;
    }
  }
}

ul.dropdown {
  position: absolute;
  left: 15px;
  top: 52px;
  width: calc(100% - 30px);
  list-style: none;
  background: #FFF;
  margin: 0;
  padding: 0;
  border-top: 1px solid #CCC;

  li {

    font-size: 18px;
    padding: 12px;
    cursor: pointer;

    &:hover {
      background: #F5F5F5;
    }
  }
}

.view-toggle {
  text-decoration: none !important;
  cursor: pointer;
}

#gmap_canvas {
  width: 100%;
  height: 600px;
  margin: 30px 0;
}

@media (min-width: 768px) {
  section {

    .photo_text_sec {
      p {
        margin: 0 0 45px;
      }
    }

    .grey-text-sec {
      h1 {
        font-size: 18px;

        &:before {
          left: 43%;
        }
      }
    }

    .white_text_sec {
      #name {
        font-size: 23px;
        margin: 10px 0 0;
      }
      #role {
        margin: 0 0 10px;
      }
    }
  }
}

@media (min-width: 1024px) {
  section {
    .grey-text-sec {
      h1 {
        font-size: 36px;

        &:before {
          left: 45%;
        }
      }
    }

    .white_text_sec {
      padding: 70px 0;

      .name-wrapper {
        padding-left: 15px;

        #name {
          margin-top: 0;
          margin: 0;
          font-size: 23px;
        }
      }

      .img-wrapper {
        padding-left: 15px;
      }

      h2 {
        font-size: 30px;
      }

      p {
        font-size: 16px;
      }

      ul {
        margin-top: 15px;
        padding: 0;
      }

      #social-buttons {
        padding: 0;
      }
    }
  }
}

@media (min-width: 1200px) {
  section {
    .grey-text-sec {
      h1 {
        font-size: 36px;
      }
    }
    .photo_text_sec {

      p {
        font-size: 17px;
      }

      .icon_text {
        .caption {
          h1 {
            font-size: 85px;
            margin: 10px;
            padding-left: 15px;
          }
        }
      }
    }
  }
}

#gmap_streetview,
#gmap_canvas {
  width: 100%;
  height: 400px;
}

.icon-bed {
  font-size: 36px !important;
}

.icon-house {
  font-size: 68px !important;
}

.form-row {
  margin: 0 0;
  margin-bottom: 15px;
}

.search-wrapper {
  .btn-search-submit {
    margin-top: 15px;
  }
}

@media(min-width: 992px) {
  .max_options_area {
    select {
      display: inline-block;
    }
  }

  .search-wrapper {
    .btn-search-submit {
      margin-top: 0;
    }
  }
}

@media(min-width: 1320px) {
  .form-row {
    margin: 0 -15px;
    margin-bottom: 15px;
  }
}

@media (max-width: 1024px) {
  header {
    .top_line {
      form {
        padding: 6px 0;
        //    //    padding: 14px 20px;
        //    //    .form-control {
        //    //      height: 32px !important;
        //    //      font-size: 18px !important;
        //    //      line-height: 1.42857143;
        //    //
        //    //      color: #000 !important;
        //    //      background-color: #fff;
        //    //      border: 1px solid #fff;
        //    //      box-shadow: none;
        //    //      border-radius: 0;
        //    //      padding: 6px 12px;
      }

      .flag {
        padding: 17px 15px;
      }
      //    //
      //    //    .dropdown {
      //    //      top: inherit;
      //    //    }
      //    //
      //    //    .btn-danger {
      //    //      color: #fff;
      //    //      background-color: $red;
      //    //      -webkit-border-radius: none;
      //    //
      //    //      font-weight: normal;
      //    //      font-size: 16px;
      //    //      text-shadow: none;
      //    //      border-radius: 0;
      //    //      width: 100%;
      //    //      border-color: $red;
      //    //      margin-top: 2px;
      //    //      height: 30px;
      //    //    }
      //    //  }
    }
  }
}

header {
  .nav_sec {
    nav {
      .navbar-header {
        form {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .zopim {
    width: 100px !important;
    height: 30px !important;
  }

}

@media (min-width: 1000px) {
  section {
    .to_top {
      .button_top {
        width: 47px;
      }
    }
  }
}

@media (min-width: 1200px) {
  section {
    .buttons-wrapper {
      .container {
        a {
          .text-wrapper {
            .caps {
              height: 45px;

              &.small {
                height: unset;
              }

              &.big {
                height: 45px;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  header .top_line .search_icon {
    margin-right: 86px;
  }
}

@media (max-width: 1200px) {
  //.container {
  //  width: 96%;
  //  margin: 0 auto;
  //  padding: 0;
  //}
  header .nav_sec .navbar-default .navbar-nav > li > a {
    padding: 16px 8px;
    font-size: 15px;
    color: #fff;
    line-height: 17px;
    margin-top: 0;
    text-shadow: none;
    display: block
  }
}

/************ Main **************/
@media (max-width: 960px) {
  header .nav_sec .navbar-default .navbar-brand {
    width: 180px;
    margin-left: 10px;
  }

}

@media (max-width: 900px) {
  header .nav_sec {
    width: 100%;
    float: left;
    padding: 0;
    margin: 0;
    text-align: center
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  //.row {
  //  margin-left: 0px;
  //  margin-right: 0px;
  //}
  header .top_line .call {
    text-align: center;
    line-height: 60px
  }
  header .top_line .navbar-brand {
    display: block;
  }
  header .top_line .navbar-brand img {
    width: 190px;
  }
  header .top_line .fag {
    float: right;
    padding: 23px 15px;
  }
  header .top_line .search_icon {
    float: right;
    padding: 23px 15px;
  }
  header .top_line #custom-search-input .search-query {
    height: 60px !important;
  }
  header .top_line .call_icon i {
    display: none;
  }

  header .nav_sec {
    width: 100%;
    float: left;
    padding: 0;
    margin: 0;
    text-align: center
  }
  header .nav_sec .navbar-default {
    padding: 0px;
    text-align: center
  }
  header .nav_sec .navbar-nav {
    float: left;
    margin: 0px;
  }
  header .nav_sec .navbar-default .navbar-nav > li > a {
    padding: 16px 8px;
    font-size: 15px;
    color: #fff;
    line-height: 17px;
    margin-top: 0;
    text-shadow: none;
    display: block
  }

  #header.affix .brand_small {
    display: none;
  }

  header .nav_sec .navbar-default .brand_small {
    display: none;
  }
  header .nav_sec .navbar-default .brand_small img {
    display: block;
    width: 80px;
  }

  section .slider_sec .slider_caption {
    position: absolute;
    left: 10%;
    right: 10%;
    bottom: 25%;
  }
  section .slider_sec .slider_caption .caption .detail {
    width: 40%;
    float: left;
    padding: 20px 10px;
    margin: 0;
    background: rgba(255, 255, 255, 0.75);
    border: #91959a 1px solid
  }
  section .slider_sec .slider_caption .caption h1 {
    width: 100%;
    float: left;
    padding: 0;
    margin: 0 0 20px 0;
    font-family: arial;
    font-weight: bold;
    font-size: 50px;
    color: #fff;
    text-shadow: -1px 1px 10px rgba(0, 3, 3, 0.75);
  }
  section .slider_sec .slider_caption .caption .btn-danger {
    color: #fff;
    background-color: #d8282f;
    border-color: #d8282f;
    border-radius: none;
    -webkit-border-radius: none;
    font-family: archivo_narrowregular;
    font-size: 30px;
    text-shadow: none;
    border-radius: 0px;
    padding: 5px 20px;
  }

  section .text_detalis h1 {
    font-size: 32px;
    line-height: 20px;
  }
  section .map_sec h1 {
    font-size: 32px;
    line-height: 50px;
  }

  section .contact_sec h2 {
    color: #31383d;
    margin: 0px;
    margin-bottom: 10px;
    margin-top: 25px;
  }

  section .top_search_sec {
    padding: 30px 0 20px 0;

  }
  section .top_search_sec .bootstrap-select {
    margin-bottom: 10px !important;
  }
  section .top_search_sec .form-control {
    margin-bottom: 10px !important;
  }
  section .red_text_sec h1 {
    font-size: 30px;
    line-height: 40px;
  }

  section .content_sec .black_box .text_reg {
    font-size: 20px;
    line-height: 30px
  }
  section .content_sec .black_box .text_reg span {
    font-size: 20px;
    line-height: 30px
  }

  section .content_sec .rightside .gray_box {
    padding: 25px 12px;
  }
  section .content_sec .rightside .gray_box h2 {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 40px;
  }
  section .content_sec .rightside .gray_box p {
    font-size: 15px;
  }

  section .content_sec .rightside .photo_box {
    border: #e0e9e9 1px solid;
    background-color: #fff;
    padding-top: 25px
  }
  section .content_sec .rightside .photo_box h2 {;
    font-size: 20px;
    line-height: 28px;
    padding: 0 12px
  }
  section .content_sec .rightside .photo_box p {
    font-size: 15px;
    padding: 0 12px;
    margin-bottom: 40px;
  }

  section .photo_text_sec .icon_text .caption {
    top: 18%;
  }
  section .betekenen_sec .box_contant {
    border-bottom: #d8282f 10px solid;
    margin-bottom: 15px;
  }

  header .nav_sec .navbar-default .navbar-nav > li #custom-search-input {
    margin: 0;
    padding: 0;
    line-height: 45px !important;
    width: 130px;
    margin-right: 10px
  }
  header .nav_sec .navbar-default .navbar-nav > li #custom-search-input .search-query {
    width: 130px;
  }
  header .nav_sec .navbar-default .navbar-nav > li .hide {
    display: none !important
  }
}

@media (max-width: 767px) {
  html {
    -webkit-text-size-adjust: none;
  }
  //.container {
  //  width: 93%;
  //  margin: 0 auto;
  //  padding: 0;
  //}
  //.row {
  //  margin-left: 0px;
  //  margin-right: 0px;
  //}
  header .top_line {
    width: 100%;
    float: left;
    padding: 0px;
    margin: 0px;
    background-color: #fff;
    border-bottom: #e5ebed 1px solid;
    display: none;
  }
  header .top_line .call {
    display: none;
  }
  header .top_line .navbar-brand {
    display: none
  }

  header .top_line .fag {
    float: right;
    padding: 35px 15px 35px 15px;
    border-right: #e5ebed 1px solid;
    border-left: #e5ebed 1px solid;
    margin-left: 15px;
  }
  header .top_line .search_icon {
    float: right;
    padding: 35px 15px 35px 15px;
    border-right: #e5ebed 1px solid;
    margin-right: 15px;
    cursor: pointer;
    display: none;
  }
  header .top_line #custom-search-input {
    margin: 0;
    padding: 0;
    line-height: 45px !important;
    width: 180px;
    float: right;
    display: block !important;
  }
  header .top_line .call_icon i {
    float: right;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    font-size: 18px;
    color: #fff;
    background-color: #d8282f;
    border-radius: 50%;
    margin: 24px 0
  }

  header .nav_sec {
    width: 100%;
    float: left;
    padding: 0;
    margin: 0;
    background-color: #fff
  }
  .navbar-default .navbar-toggle {
    background: none;
    border-color: transparent;
    border-radius: 0;
    margin-top: 18px;
    margin-right: 0 !important;
  }
  .navbar-default .navbar-toggle .icon-bar {
    background: #d8282f;
  }
  .navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
    background: none;
  }

  header .nav_sec .navbar-nav {
    float: none;
    margin: 0px;
  }
  header .nav_sec .navbar-default .navbar-nav > li > a {
    padding: 8px 0px;
    text-align: left;
    border-right: none;
    font-size: 18px;
    color: #000;
    line-height: 18px;
    margin-top: 0px;
    text-shadow: none;
  }
  header .nav_sec .navbar-default .navbar-nav > li .input-group-btn {
    border-right: none;
    padding: 0px;
  }

  header .nav_sec .call_icon i {
    display: block;
  }

  header .nav_sec .navbar-default .brand_small {
    display: block;
    float: left;
    margin-right: 30px;
  }
  header .nav_sec .navbar-default .brand_small img {
    display: block;
    width: 60px;
  }
  header .nav_sec .navbar-default .navbar-nav .btn-primary {
    width: 100%
  }

  section .slider_sec .carousel-inner > .item > img, section .slider_sec .carousel-inner > .item > a > img {
    width: 100%;
    height: 250px
  }
  section .slider_sec .slider_caption {
    position: absolute;
    left: 10%;
    right: 0%;
    bottom: 20%;
  }
  section .slider_sec .slider_caption .caption h1 {
    font-size: 20px;
    color: #fff;
  }
  section .slider_sec .slider_caption .caption .btn-danger {
    font-size: 15px;
  }

  section .tab_details .nav-tabs > li {
    float: left;
    width: 100% !important;
  }
  section .tab_details .nav-tabs > li.active > a,
  section .tab_details .nav-tabs > li.active > a:hover,
  section .tab_details .nav-tabs > li.active > a:focus {
    border-bottom: #d8282f 1px solid;
  }

  section .text_detalis h1 {
    font-size: 32px;
    line-height: 40px;
  }
  section .map_sec h1 {
    font-size: 32px;
    line-height: 40px;
    margin-top: 15px;
  }
  section .btn_sec .btn-danger {
    margin-bottom: 10px;
  }
  section .btn_sec .icon_sec {
    float: none;
    margin: 0 auto;
    text-align: center
  }
  section .red_form_sec .form-control {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 10px;
  }
  section .red_form_sec .btn-danger {
    width: 100%;
  }

  footer .footer_bg .footer_logo li {
    width: 100%;
    margin: 0px;
  }
  footer .footer_bg .icon_sec {
    float: left;
    margin-top: 10px;
  }
  footer .footer_bg .icon_sec .icon_md {
    width: 10%;
    float: left
  }
  footer .footer_bg .icon_sec .icon_md img {
    width: 100%;
    height: auto
  }

  section .contact_sec h1 {
    font-size: 40px;
    line-height: 80px;
  }
  section .contact_sec h2 {
    margin-top: 15px;
    font-size: 25px;
  }

  section .top_search_sec {
    padding: 30px 0 20px 0;
  }
  section .top_search_sec .bootstrap-select {
    margin-bottom: 10px !important
  }
  section .top_search_sec .form-control {
    margin-bottom: 10px !important
  }

  section .red_title_sec h1 {
    font-size: 48px;
    line-height: 80px;
    margin: 0px;
  }
  section .red_title_sec ul.icon_link {
    float: right;
    padding: 0;
    margin: 0px;
  }
  section .red_title_sec ul.icon_link li a i {
    width: 52px;
    padding: 0px;
    height: 52px;
    line-height: 52px;
  }
  section .red_title_sec .dropdown_small {
    float: left;
    margin: 0px;
  }

  section .red_text_sec h1 {
    font-size: 30px;
    line-height: 40px;
  }
  section .red_text_sec .btn-danger {
    width: 100%;
    padding: 0px;
  }

  section .content_sec .black_box {
    padding: 25px;
    margin-bottom: 40px;
  }
  section .content_sec .black_box .text_reg {
    font-size: 20px;
    line-height: 30px
  }
  section .content_sec .black_box .text_reg span {
    font-size: 20px;
    line-height: 30px
  }

  section .content_sec .rightside .gray_box {
    padding: 25px 12px;
  }
  section .content_sec .rightside .gray_box h2 {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 40px;
  }
  section .content_sec .rightside .gray_box p {
    font-size: 15px;
  }

  section .content_sec .rightside .photo_box {
    border: #e0e9e9 1px solid;
    background-color: #fff;
    padding-top: 25px
  }
  section .content_sec .rightside .photo_box h2 {;
    font-size: 20px;
    line-height: 28px;
    padding: 0 12px
  }
  section .content_sec .rightside .photo_box p {
    font-size: 15px;
    padding: 0 12px;
    margin-bottom: 40px;
  }

  section .photo_text_sec h1 {
    font-size: 24px;
  }
  section .photo_text_sec .btn-danger {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  section .photo_text_sec .icon_text .caption h1 {
    font-size: 90px;
  }
  section .photo_text_sec .icon_text h2 {
    margin-bottom: 15px
  }

  section .betekenen_sec .box_contant {
    border-bottom: #d8282f 10px solid;
    margin-bottom: 15px;
  }

  section .de_mensen_achter_sec .btn-danger {
    width: 100%;
    padding: 0px;
  }
  #header.affix .menu_logo {
    display: none;
  }

  section .box_i_sec {
    display: none;
  }

  section .box_i_sec .box .form-control, .btn.dropdown-toggle.btn-default {
    margin-bottom: 7px;
    height: 34px !important;
    font-size: 18px !important;
    line-height: 14px !important;
  }
  section .box_i_sec .box .btn-danger {
    line-height: 38px !important;
  }
  section .box_i_sec .box textarea.form-control {
    height: 80px !important;
  }

}

/************ iphone **************/
@media (max-width: 600px) {
  html {
    -webkit-text-size-adjust: none;
  }
  //.container {
  //  width: 91%;
  //}

}

@media (min-width: 768px) {
  .col-sm-5ths {
    width: 20%;
    float: left;
  }
}

@media (max-width: 480px) {
  section .photo_text_sec .btn-danger {
    /*    font-size: 15px !important;
        width: 100%;
        text-align:center;*/
    white-space: normal !important;
    line-height: 35px !important;
  }
}

@media (min-width: 992px) {
  .col-md-5ths {
    width: 20%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-5ths {
    width: 20%;
    float: left;
  }
}

@media (min-width: 361px) and (max-width: 767px) {
  .icon_text .col-xs-12 {
    width: 50% !important
  }
}

@media (min-width: 361px) and (max-width: 500px) {
  .icon_text .col-xs-12 .caption h1 {
    font-size: 54px !important;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 22px;
    line-height: 1.3;
  }

  h2 {
    font-size: 20px;
    line-height: 1.3;
  }

  h3 {
    font-size: 18px;
    line-height: 1.2;
  }

  h4 {
    font-size: 16px;
    line-height: 1.1;
  }

  .btn.btn-danger {
    font-size: 16px !important;
  }

  .red_form_sec .tabs-holder nav .tabs .tab {
    font-size: 16px;
    line-height: 22px;
    padding: 5px 0;
    height: 55px;
  }

  .search-wrapper {

  }
}