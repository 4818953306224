#search-enquiries-details {
    background-color: #fff;
    .object {
        background-color: #F5F5F5;
        max-width: 690px;
        margin-left: auto;
        margin-right: auto;
        .content{
            
            padding: 15px 20px;
            color: black;   
    
            h3{
                font-weight: bold;
                font-size: 18px;
            }
            .price{
                
                color: #666666;
                font-size: 14px;
                padding: 5px 10px;
                background-color:#E0E1E2;
            }
            .more{
                text-decoration: underline;
                color: #24387C;
            }
            p{
                font-size: 16px;
                margin: 10px 0px
            }
            ul{
                margin-top: 10px;
                padding: 0;
                list-style-type: none;
                
                li{
                    color: #666666;
                    font-size: 14px;
                    i{
                        font-size: 14px !important;
                        margin-right: 14px;

                        &.icon-bed{
                            margin-right: 8px;
                        }
                    }
                
                    
                   

                }
            }
        }
        .footer{
            padding: 20px;
            border-top: 2px solid #E0E1E2;
            border-style: dotted none none none;
            font-size: 14px;
            position: relative;
            .footer-buttons{
                display: block;
                .btn-red{
                    margin-top: 10px;
                    padding: 10px 15px;
                    display: inline-block;
                   
                }
                .back{
                  display: inline-block;
               
                  position: absolute;
                 margin: 20px;
                }
            }
            
        }
        
    }
}
