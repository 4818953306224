%modal {
    border-radius: 5px;
    background-color: #ffffff;
    border: 1px solid $light-grey;
}

%input {
    width: 100%;
    border-radius: 5px;
    border: 1px solid $light-grey;
    padding: 7px;
    outline: 0;
}

%radio-input {
    .radio-label {
        width: 50%;
        font-weight: normal;
        position: relative;
        display: block;
        padding-left: 35px;
        cursor: pointer;

        input[type="radio"] {
            display: none;
        }

        .checked {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            background-color: #ffffff;
            border: 1px solid $light-grey;
            border-radius: 50%;
        }

        .checked:after {
            content: "";
            position: absolute;
            display: none;
        }

        input:checked ~ .checked:after {
            display: block;
        }

        .checked:after {
            left: 4px;
            top: 4px;
            width: 10px;
            height: 10px;
            background-color: $new-red;
            border-radius: 50%;
        }
    }
}

%checkbox-input {
    .checkbox-label {
        width: 100%;
        font-weight: normal;
        position: relative;
        cursor: pointer;
        margin-bottom: 0;
        display: flex;
        align-items: center;

        input[type="checkbox"] {
            display: none;
        }

        .checked {
            height: 28px;
            width: 28px;
            background-color: #ffffff;
            border: 1px solid $light-grey;
            border-radius: 3px;
            margin-right: 15px;
            flex-shrink: 0;
        }

        .checked:after {
            content: "";
            position: absolute;
            display: none;
        }

        input:checked ~ .checked:after {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .checked:after {
            left: 0;
            height: 28px;
            width: 28px;
            background-color: $new-red;
            border-radius: 3px;
            font-size: 14px;
            color: #ffffff;
            font-family: icomoon;
            content: '\e90b';
        }
    }
}



@media (min-width: $screen-lg-min) {
    %button {
        padding: 15px 20px;
    }
}