#account {
    .panel {
        margin-top: 15px;
    }

    hr {
        border: 0;
        border-top: 1px solid #eee;
        margin: 20px 0;
    }

    .list-group {
        margin: 0;

        .list-group-header {
            position: relative;
            display: block;
            padding: 10px 15px;
            margin-bottom: -1px;
            color: #fff;
            font-weight: 700;
            background-color: $red;
            border: 1px solid $red;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;

            a {
                color: #FFF;
                text-decoration: none;
            }
        }

        .list-group-item {
            &.active {
                background-color: rgba($red, 0.1);
                border-color: #ddd;
                color: #fff;
            }
            a{
                color: #000;
                text-decoration: none;
            }
        }
    }

    .object_wrapper {
        .object {
            display: block;
            outline: none;
            border-radius: 5px;
            overflow: hidden;
            background: #FFF;
            box-shadow: 0px 25px 80px 0px rgba(0, 0, 0, 0.1);
            color: #202020;

            figure {
                position: relative;

                img {
                    display: block;
                    width: 100%;
                }

                .label {
                    position: absolute;
                    top: 30px;
                    left: 0;
                    display: inline-block;
                    background: $red;
                    color: #fff;
                    padding: 10px 30px;
                    font-weight: bold;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }

                .overlay {
                    opacity: 0;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, transparent 100%);
                    transition: all 0.3s;

                    span {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        color: $red;
                        font-size: 80px;
                    }
                }
            }

            &:hover {
                figure {
                    .overlay {
                        opacity: 1;
                    }
                }
            }

            .object_content {
                position: relative;
                padding: 15px 15px 45px 15px;

                h3 {
                    color: #202020;
                    font-size: 24px;
                }

                h4 {
                    color: #999;
                    font-weight: normal;
                    font-size: 22px;
                    margin: 0;
                }

                ul {
                    list-style: none;
                    margin: 15px 0 0 0;
                    padding: 0;
                    overflow: hidden;

                    li {
                        float: left;
                        width: 50%;
                        margin-bottom: 15px;

                        span {
                            color: $red;
                        }
                    }
                }

                h5 {
                    position: absolute;
                    left: 15px;
                    bottom: 15px;
                    margin: 0;
                    font-weight: normal;
                    font-size: 24px;
                }
            }
        }
    }

    .auction {
        .panel-heading {
            border-bottom: 1px solid #f5f5f5;

            a {
                h3 {
                    transition: all 300ms ease;

                    .icon {
                        float: right;
                        transform: translateY(3px);
                    }
                }

                &:hover {
                    h3 {
                        color: $red;
                    }
                }
            }
        }

        .panel-body {
            .bids {
                margin: 0;
                padding: 0;

                li {
                    list-style: none;
                    padding: 5px 10px;

                    &:nth-child(odd) {
                        background: #f2f2f2;
                    }
                }
            }
        }
    }
    .dl-horizontal{
        max-width: 400px;

        dt, dd {
            float: left;
            width: 50%;
            margin: 0;
            line-height: 2;
            text-align: left;
        }
    }



}

// ***************************************************************** 
// SM
// ***************************************************************** 

@media (min-width: $screen-sm-min) {
    #account {
        .panel {
            margin-top: 0px;
        }
    }
}

// ***************************************************************** 
// MD
// ***************************************************************** 

@media (min-width: $screen-md-min) {
}

// ***************************************************************** 
// LG
// ***************************************************************** 

@media (min-width: $screen-lg-min) {
}