.top_search_sec {
  width: 100%;
  background-color: #fff;
  margin: 0;
  padding: 30px 0;

  .btn-search-submit {
    //font-size: 20px !important;
    padding: 7px !important;
    background: $red;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    border: none;
  }

  .top_search_title {
    text-align: center;
    color: #fff;

    h2 {
      margin-top: 0;
      margin-bottom: 25px;
      color: #000;
    }
  }

  .btn.dropdown-toggle {
    &.btn-default {
      background-color: $red;
      height: 50px;
      color: #fff !important;
      border-radius: 0 !important;

      font-size: 20px;
      margin-bottom: 0;
      border-color: $red;
    }

    &hover {
      background: darken($red, 15%);
    }
  }

  .bootstrap-select {
    margin-bottom: 0 !important;
  }

  .form-control {
    float: left;
    width: 100%;
    //height: 46px;
    line-height: 1.42857143;

    color: #fff;
    background-color: $dark-grey2;
    border: 1px solid $dark-grey2;
    box-shadow: none;
    border-radius: 0;
    padding: 6px 12px;

    &::placeholder {
      color: #fff;
    }
  }

  .dropdown-menu {
    border-radius: 0;

    background-color: #5a6064;
    border: #5a6064 1px solid;
    margin: 2px 0 0;
    padding: 0;
  }

  .dropdown-menu > li > a {
    color: #fff;

    &:hover, &:focus {
      color: #fff;
      text-decoration: none;
      background-color: #394045;
    }
  }
}

@media (min-width: 1024px) {
  .top_search_sec {
    form > .row {
      > .col-md-2 {
        width: 13% !important;

        //&:nth-child(1),
        //&:nth-child(2) {
        //  width: 14% !important;
        //}
        //&:nth-child(3),
        //&:nth-child(4) {
        //  width: 14% !important;
        //}

        &:last-child {
          width: 35% !important;
        }
      }

      div > .col-md-2 {
        width: 13% !important;
      }
    }
  }
}

@media (min-width: 1260px) {
  .top_search_sec {
    form > .row {
      > .col-md-2 {
        width: 14% !important;

        //&:nth-child(1),
        //&:nth-child(2) {
        //  width: 14% !important;
        //}
        //&:nth-child(3),
        //&:nth-child(4) {
        //  width: 14% !important;
        //}

        &:last-child {
          width: 28% !important;
        }
      }

      div > .col-md-2 {
        width: 15% !important;
      }
    }
  }
}