#search-enquiries-place-header{
    padding: 30px 0px;
    border-top: 10px solid $new-red;
    height: 400px;
    margin-top: 0;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.2)), url('/img/search-enquiries-header.png'), no-repeat;
    color: white;
    .container{
        position: relative;
        height: 100%;
        .header-content{
          
          
            width: 60%;
            p{
                font-weight: 300;
            }
        }
    }

   
}

main {
    
    background-color: #F5F5F5;
    padding: 40px 25px;

    #search-enquiries-place-container {
        @extend %modal;

        #steps {
            display: none;
        }

        #content {
            h2 {
                font-size: 24px;
                font-weight: 800;
            }

            form {
                color: black;
                padding: 22px;
                display: flex;
                align-items: flex-start;
                overflow: hidden;

                .step, .end {
                    flex-shrink: 0;
                    flex-basis: 100%;
                    animation-duration: 1s;
                    animation-fill-mode: forwards;
                    background-color: #ffffff;
                    margin-left: 38px;

                    strong {
                        display: block;
                        margin-bottom: 15px;
                    }

                    &:first-of-type {
                        margin-left: 0;
                    }

                    > div:not(.checkbox-container) {
                        margin-bottom: 15px;

                        label {
                            width: 100%;
                            font-weight: 800;
                        }

                        input, select, textarea {
                            @extend %input;
                        }

                        textarea {
                            height: 125px;
                        }
                    }

                    .checkbox-container {
                        width: 100%;
                        border: 1px solid rgba(36, 56, 124, 0.1);
                        box-shadow: 0 2px 5px rgba(36, 56, 124, 0.1);
                        padding: 16px 20px;
                        margin-bottom: 15px;

                        @extend %checkbox-input;
                    }

                    div[role="group"] {
                        margin-bottom: 15px;

                        > span {
                            width: 100%;
                            margin-bottom: 5px;
                            font-weight: 700;
                            line-height: 1.5;
                            font-size: 16px;
                            border-bottom: 0;
                            color: #000000;
                            display: block;
                        }

                        > div {
                            display: flex;

                            label {
                                width: 50%;
                                font-weight: normal;
                                display: flex;
                                align-items: center;
                                margin-bottom: 0;

                                &:last-of-type {
                                    margin-left: 15px;

                                    span {
                                        text-align: right;
                                    }
                                }

                                span {
                                    width: 45px;
                                    flex-shrink: 0;
                                }

                                select {
                                    @extend %input;
                                    margin-left: 15px;
                                }
                            }

                            @extend %radio-input;
                        }
                    }

                    button {
                        margin-top: 10px;
                        float: right;
                        background-color: $new-red;
                        color: white;
                        border-radius: 0;
                        padding: 15px 20px;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    #search-enquiries-place-header {
        > div {
            display: flex;
            align-items: center;

            > div {
                text-align: left;
                width: 60%;
                flex-shrink: 0;
                padding-left: 0;
                padding-right: 0;
            }

            img {
                display: block;
                width: 220px;
                margin-left: auto;
            }
        }
    }

    main {
        #search-enquiries-place-container {
            #steps {
                background-color: #fff;
                border-bottom: 1px dotted #54565a;
          
                padding-top: 35px;
                padding-bottom: 35px;
                display: flex;
                justify-content: center;

                #inner {
                    > div {
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;

                        &:last-of-type {
                            display: flex;
                            width: 100%;
                            margin-top: 15px;

                            div {
                                width: 34%;

                                > span {
                                    color: #000;
                                    animation-duration: 1s;
                                    animation-fill-mode: forwards;
                                }

                                &:first-of-type {
                                    span {
                                        margin-left: -22px;
                                    }
                                }

                                &:nth-child(2) {
                                    display: flex;
                                    justify-content: center;
                                }

                                &:last-of-type {
                                    display: flex;
                                    justify-content: flex-end;

                                    span {
                                        margin-right: -10px;
                                    }
                                }
                            }
                        }
                    }
                }

                .step-container {
                    width: 28px;
                    height: 28px;
                    border-radius: 50%;
                    border: 1px solid $new-red;
                    background-color: white;
                    color: #000;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: 800;
                    margin-right: 10px;
                    margin-left: 10px;
                    padding-top: 1px;
                    position: relative;

                    &:after {
                        font-size: 16px;
                        animation-duration: 0.5s;
                        animation-fill-mode: forwards;
                    }

                    &.completed {
                        &:after {
                            animation-name: change-check;
                        }
                    }

                    &.current {
                        background-color: $new-red;
                        color: white;
                    }
                }

                #step-container-1 {
                    margin-left: 0;
                    animation-duration: 1s;
                    animation-fill-mode: forwards;

                    &:after {
                        content: '1';
                    }
                }

                #step-container-2 {
                    animation-delay: 1.75s;
                    animation-duration: 1s;
                    animation-fill-mode: forwards;

                    &:after {
                        content: '2';
                    }
                }

                #step-container-3 {
                    animation-duration: 1s;
                    animation-fill-mode: forwards;
                    margin-right: 0;

                    &:after {
                        content: '3';
                    }
                }

                .progress-container {
                    height: 28px;
                    display: flex;
                    align-items: center;

                    > div {
                        height: 5px;
                        width: 5px;
                        background-color: $new-red;
                        border-radius: 50%;
                        margin-right: 5px;
                        animation-duration: 1s;
                        animation-fill-mode: forwards;

                        &:last-of-type {
                            margin-right: 0;
                        }
                    }
                }
            }

            #content {

                h2 {
                    font-size: 30px;
                }

                form {
                    padding: 38px;

                    .step, .end {
                        > div:not(.checkbox-container) {
                            display: flex;
                            align-items: center;

                            > label {
                                padding-right: 50px;
                            }

                            label {
                                width: 45%;
                                flex-shrink: 0;

                                &[for="additional-information"] {
                                    align-self: flex-start;
                                }
                            }

                            input, select {
                                padding: 12px;
                                max-height: 50px;
                            }
                        }

                        div[role="group"] {
                            display: flex;
                            padding-top: 9px;

                            > span {
                                width: 45%;
                                flex-shrink: 0;
                            }

                            > div {
                                width: 100%;

                                label {
                                    select {
                                        height: 50px;
                                    }

                                    &:last-of-type {
                                        margin-left: 0;
                                    }
                                }
                            }
                        }
                    }

                    .end {
                        display: flex;

                        > div:not(.checkbox-container) {
                            display: block;

                            &:first-of-type {
                                margin-right: 150px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    #search-enquiries-place-header {
        > div {
            > div {
                h1 {
                    font-size: 29px;
                }

                p {
                    font-size: 18px;
                }
            }

            img {
                width: 260px;
            }
        }
    }

    main {
        #search-enquiries-place-container {
            max-width: 930px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

@media (min-width: $screen-lg-min) {
    #search-enquiries-place-header {
        > div {
            max-width: 1440px;
            margin-left: auto;
            margin-right: auto;
            padding-left: 15px;
            padding-right: 15px;

            > div {
                width: 65%;
                padding-top: 85px;
                padding-bottom: 85px;

                h1 {
                    font-size: 40px;
                }

                p {
                    font-size: 18px;
                    line-height: 27px;
                }
            }

            img {
                width: 320px;
            }
        }
    }
}