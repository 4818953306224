#landingpage {
  .red_text_sec {
    border-top: 7px solid $dark-grey;
  }

  .contact {
    margin: 30px 0;
    background: $red;
    padding: 20px;

    h2 {
      color: #fff;
    }

    .form-control {
      border-radius: 0;
      border: 1px solid $dark-grey2
    }

    .has-error {
      .help-block {
        color: #fff;
      }
    }

    .g-recaptcha {
      margin-bottom: 13px;
    }

    .btn-green {
      border-radius: 0;
      background: $green;
      color: #fff;
    }
  }
}