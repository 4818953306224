footer {
  .footer-links {
    text-align: center;

    ul {
      list-style: none;
      padding: 0 0 15px 0;
      margin: 0;

      li {
        position: relative;
        display: inline-block;
        padding: 0 10px;
        color: #ccc;

        a {
          color: #ccc;
        }

        &:after {
          content: '';
          position: absolute;
          height: 100%;
          width: 1px;
          background: #ccc;
          right: 0;
        }

        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }

    a {
      &:hover {
        color: #d8282f;
      }
    }
  }

  .footer_bg {
    float: left;
    width: 100%;
    background-color: #32383d;
    margin: 0;
    padding: 50px 0 0;

    h4 {
      line-height: 30px;
      color: #fff;
      margin: 0 0 5px;
    }

    p {
      color: #fff;
      margin: 0;
      padding: 0;
    }

    .call {
      color: #fff;
      margin: 30px 0 0;
      a {
        color: #fff;

        &:hover {
          color: darken($red, 10%);
          text-decoration: none;
        }
      }
    }

    .icon_sec {
      float: right;
      margin-top: 45px;

      .icon_md {
        float: left;
        margin: 0 3px 6px;
      }
    }

    .line {
      background-color: #474c51;
      height: 1px;
      margin: 30px 0;
    }

    .footer_logo {
      margin: 20px 0;
      padding: 0;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }

      li {
        width: auto;
        height: 100px;
        text-align: center;
        display: inline-block;
        margin: 0 14px;
      }
    }
    label {
      color: #fff;
    }

  }

  .copyright {

    font-size: 18px;
    color: #d3d4d5;
    line-height: 24px;
    text-align: center;
    background-color: #fff;
    padding: 30px 0;
  }

}

@media (max-width: 1024px) {
  h4 {
    font-size: 16px;
  }
}
