.btn-green {
  background-color: #7ec648 !important;
  color: #fff !important;
  border: 1px solid $green !important;

  &:hover {
    color: #fff !important;
  }
}
.btn-red{
 
  padding: 10px 15px;
  background-color: $new-red;
  color: white;
  border-radius: 0;
  &:hover{
    color: white;
    background-color: darken($color: $new-red, $amount: 10%);
}
}